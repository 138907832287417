/**
 * @file
 *
 * This component is used to debounce frequent onChange events for better performance when part of a bigger form
 */
import React, { useState, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { TextField as Mui5TextField } from '@mui/material';
import { useUnmount } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';

import { HelperTextAdornment, MUI5HelperTextAdornment } from './HelperTextAdornment';

export function DebouncedTextField({
  value,
  onChange,
  validate,
  cast,
  helperText,
  forceDefaultValueOnBlur = false,
  defaultValue,
  isMUI5 = false,
  ...rest
}) {
  const [internalValue, updateInternalValue] = useState(value);

  const [debouncedOnChange, cancel, flush] = useDebouncedCallback(onChange, 500, {
    maxWait: 2000,
    leading: true,
  });

  const [isError, setIsError] = useState(() => (validate ? !validate(value) : false));

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;

      if (validate) {
        const isValid = validate(value);

        if (isValid) {
          debouncedOnChange(cast ? cast(value) : value);
          setIsError(false);
        } else {
          debouncedOnChange(null);
          setIsError(true);
        }
        updateInternalValue(value);
      } else {
        updateInternalValue(value);
        debouncedOnChange(value);
      }
    },
    [debouncedOnChange, validate, cast]
  );

  const handleBlur = useCallback(() => {
    if (
      forceDefaultValueOnBlur &&
      (internalValue === undefined || internalValue === null || internalValue === '')
    ) {
      if (defaultValue !== undefined && validate(defaultValue)) {
        const value = cast(defaultValue);

        updateInternalValue(value);
        setIsError(false);
      }
    } else {
      flush();
    }
  }, [flush, internalValue, defaultValue, cast, validate, forceDefaultValueOnBlur]);

  const handleEnterKey = useCallback(
    (event) => {
      // form submit by enter to immediately flush the changes so that query is upto date
      if (event.key === 'Enter') {
        flush();
      }
    },
    [flush]
  );

  useUnmount(cancel);

  return isMUI5 ? (
    <Mui5TextField
      {...rest}
      value={internalValue}
      onChange={handleChange}
      onKeyDown={handleEnterKey}
      onBlur={handleBlur}
      error={isError}
      InputProps={{
        startAdornment: (
          <MUI5HelperTextAdornment error={isError} text={helperText ?? rest?.placeholder ?? ''} />
        ),
      }}
    />
  ) : (
    <TextField
      {...rest}
      value={internalValue}
      onChange={handleChange}
      onKeyDown={handleEnterKey}
      onBlur={handleBlur}
      error={isError}
      InputProps={{
        startAdornment: (
          <HelperTextAdornment error={isError} text={helperText ?? rest?.placeholder ?? ''} />
        ),
      }}
    />
  );
}
