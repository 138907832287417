/**
 * @file
 *
 * this file contains the reusable stepper dialog component
 */

import {
  Alert,
  CircularProgress,
  DialogContentText,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Step,
  TextField,
  Typography,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

import DialogWrapper from 'components/DialogWrapper';
import {
  extractEntityFromQueryString,
  getStepperSteps,
  setStepperDialogPrimaryBtnText,
} from 'reportView/utils';
import { commonTextFieldProps } from 'mui5Theme';
import { useReportViewState } from 'state/reportView';
import { NotFoundImage } from 'components/Illustrations/NotFound';
import GenericSuccessIllustration from 'components/Illustrations/GenericSuccessIllustration';
import { getSystemConfiguration, navigateToSystems } from 'utils';
import { STEPPER_FLOWS } from './constants';
import { useTenantState } from 'data/user';
import { SYSTEMS } from './constants';

export default function CommonStepperDialog({
  connection,
  isCommonStepperDialogOpen,
  closeCommonStepperDialog,
  dialogSize,
  finalActionBtnProps,
  intialStep,
  stepperFlow,
  stepperFlowProps,
}) {
  const [activeStep, setActiveStep] = useState(intialStep ?? 0);
  const [isMetadataConfigured, setIsMetadataConfigured] = useState(false);
  const [isPicklistConfigured, setIsPicklistConfigured] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const queryClient = useQueryClient();
  const tenant = useTenantState();

  // Get the updated user-systems from the cache
  const userSystemEndpoint = `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`;
  const userSystems = queryClient.getQueryData([userSystemEndpoint, tenant?.tenant_id]);

  useEffect(() => {
    if (!isCommonStepperDialogOpen && stepperFlow !== STEPPER_FLOWS.RETRIGGER_EXPORT_JOB) {
      return;
    }
    if (stepperFlow === STEPPER_FLOWS.RETRIGGER_EXPORT_JOB.KEY) {
      return;
    }
    // Before export we need to refresh the user-system cache
    queryClient.invalidateQueries([
      `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`,
      tenant?.tenant_id,
    ]);

    if (isMetadataConfigured) {
      setActiveStep(1);
      return;
    }

    setActiveStep(0);
  }, [
    isCommonStepperDialogOpen,
    isMetadataConfigured,
    queryClient,
    stepperFlow,
    tenant?.tenant_id,
  ]);

  useEffect(() => {
    if (stepperFlow === STEPPER_FLOWS.RETRIGGER_EXPORT_JOB.KEY) {
      return;
    }
    const refreshSystemConfigState = async () => {
      // Extract the metadata
      const { metadata, picklist } = getSystemConfiguration(
        userSystems,
        connection?.user_system_id
      );

      setIsMetadataConfigured(Boolean(metadata));
      setIsPicklistConfigured(Boolean(picklist));
    };

    refreshSystemConfigState();
  }, [connection?.user_system_id, stepperFlow, userSystems]);

  const {
    btnAction: finalStepBtnAction,
    btnText: finalStepBtnText,
    btnProps: finalStepBtnProps,
  } = finalActionBtnProps;

  const stepperSteps = getStepperSteps(stepperFlow);

  const isLastStep = activeStep === stepperSteps.length - 1;

  const handlecloseStepperDialog = useCallback(() => {
    closeCommonStepperDialog();
    setIsPasswordValid(false);
    setActiveStep(0);
  }, [closeCommonStepperDialog]);

  const goToPreviousStep = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((state) => state - 1);
      setIsPasswordValid(false);
      return;
    }
  }, [activeStep]);

  const goToNextStep = useCallback(() => {
    if (activeStep === 0 && stepperFlow !== STEPPER_FLOWS.RETRIGGER_EXPORT_JOB.KEY) {
      // When user clicks on the Continue button -> invalidate the user-system cache
      queryClient.invalidateQueries([
        `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`,
        tenant?.tenant_id,
      ]);

      if (isMetadataConfigured) {
        setActiveStep((state) => state + 1);
        return;
      }
      return;
    }

    if (!isLastStep) {
      setActiveStep((state) => state + 1);
    }

    if (isLastStep) {
      finalStepBtnAction();
      handlecloseStepperDialog();
      setActiveStep(0);
    }
    queryClient.invalidateQueries([
      `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`,
      tenant?.tenant_id,
    ]);
  }, [
    activeStep,
    stepperFlow,
    isLastStep,
    queryClient,
    tenant?.tenant_id,
    isMetadataConfigured,
    finalStepBtnAction,
    handlecloseStepperDialog,
  ]);

  const primaryBtnText = setStepperDialogPrimaryBtnText(
    activeStep,
    stepperSteps.length,
    finalStepBtnText
  );

  const setPrimaryBtnProps = useCallback(
    (finalStepBtnProps) => {
      if (stepperFlow !== STEPPER_FLOWS.SYSTEM_CONFIGURATION.KEY && isLastStep) {
        return { ...finalStepBtnProps, disabled: !isPasswordValid };
      }
    },
    [isLastStep, isPasswordValid, stepperFlow]
  );

  const primaryBtnProps = setPrimaryBtnProps(finalStepBtnProps);

  return (
    <>
      <DialogWrapper
        title={stepperSteps.length === 1 ? stepperSteps[0] : null}
        isOpen={isCommonStepperDialogOpen}
        closeDialog={closeCommonStepperDialog}
        size={dialogSize}
        primaryBtnAction={goToNextStep}
        primaryBtnText={primaryBtnText}
        primaryBtnProps={primaryBtnProps}
        secondaryBtnAction={handlecloseStepperDialog}
        secondaryBtnText="close"
        otherBtnAction={activeStep > 0 ? goToPreviousStep : null}
        otherBtnText={activeStep > 0 ? 'Back' : ''}
        disableBackdropClick={true}
      >
        <Stepper activeStep={activeStep}>
          {stepperSteps.length > 1 &&
            stepperSteps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
        </Stepper>
        {getSteps(
          activeStep,
          stepperFlow,
          stepperFlowProps,
          connection,
          isMetadataConfigured,
          isPicklistConfigured,
          setIsPasswordValid
        )}
      </DialogWrapper>
    </>
  );
}

function getSteps(
  activeStep,
  stepperFlow,
  stepperFlowProps,
  connection,
  isMetadataConfigured,
  isPicklistConfigured,
  setIsPasswordValid
) {
  const navigateToSystemConfiguration = () => {
    navigateToSystems(connection?.tenant_id, connection?.user_system_id);
  };
  if (stepperFlow === STEPPER_FLOWS.CREATE_EXPORT_JOB.KEY) {
    const {
      showPicklistValues,
      handleShowPicklistValues,
      sheetNameAccessor,
      handleSheetNameChange,
      isCreatingJob,
      queryUrl,
      setFilePassword,
    } = stepperFlowProps;

    switch (activeStep) {
      case 0:
        return (
          <MetadataConfigurationStep
            navigateToSystemConfiguration={navigateToSystemConfiguration}
            isMetadataConfigured={isMetadataConfigured}
          />
        );

      case 1:
        return (
          <PicklistConfigurationStep
            isPicklistConfigured={isPicklistConfigured}
            showPicklistValues={showPicklistValues}
            handleShowPicklistValues={handleShowPicklistValues}
            navigateToSystemConfiguration={navigateToSystemConfiguration}
          />
        );

      case 2:
        return (
          <SheetCustomizationStep
            sheetNameAccessor={sheetNameAccessor}
            handleSheetNameChange={handleSheetNameChange}
            isCreatingJob={isCreatingJob}
            queryUrl={queryUrl}
          />
        );

      case 3:
        return (
          <PasswordConfigurationStep
            setIsPasswordValid={setIsPasswordValid}
            setFilePassword={setFilePassword}
          />
        );

      default:
        return null;
    }
  }
  if (stepperFlow === STEPPER_FLOWS.SYSTEM_CONFIGURATION.KEY) {
    const { showPicklistValues, handleShowPicklistValues } = stepperFlowProps;
    switch (activeStep) {
      case 0:
        return (
          <MetadataConfigurationStep
            navigateToSystemConfiguration={navigateToSystemConfiguration}
            isMetadataConfigured={isMetadataConfigured}
            isRunQueryFlow={true}
          />
        );

      case 1:
        return (
          <PicklistConfigurationStep
            isPicklistConfigured={isPicklistConfigured}
            showPicklistValues={showPicklistValues}
            handleShowPicklistValues={handleShowPicklistValues}
            navigateToSystemConfiguration={navigateToSystemConfiguration}
            isRunQueryFlow={true}
          />
        );

      default:
        return null;
    }
  }
  if (stepperFlow === STEPPER_FLOWS.RETRIGGER_EXPORT_JOB.KEY) {
    const { setFilePassword } = stepperFlowProps;
    return (
      <PasswordConfigurationStep
        setIsPasswordValid={setIsPasswordValid}
        setFilePassword={setFilePassword}
        isRetriggerJobFlow={true}
      />
    );
  }
}

function MetadataConfigurationStep({
  navigateToSystemConfiguration,
  isMetadataConfigured,
  isRunQueryFlow,
}) {
  if (isMetadataConfigured) {
    return (
      <Stack height="100%" alignItems="center" m={1}>
        <GenericSuccessIllustration width="200px" height="200px" />
        <Typography color="textSecondary" align="center">
          The system metadata is configured properly. You're now ready to move on - please click the
          'Next' button to continue.
        </Typography>
      </Stack>
    );
  }
  if (!isMetadataConfigured && isRunQueryFlow) {
    return (
      <Stack height="100%" alignItems="center" m={1}>
        <NotFoundImage width="180px" height="200px" />
        <Typography color="textSecondary" align="center">
          If you want to view picklist values, System metadata configuration is required. Please
          configure the metadata to continue
          <Link
            sx={{ cursor: 'pointer', ml: 1 }}
            onClick={navigateToSystemConfiguration}
            fontWeight="600"
          >
            Configure Metadata
          </Link>
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack height="100%" alignItems="center" m={1}>
      <NotFoundImage width="180px" height="200px" />
      <Typography color="textSecondary" align="center">
        System metadata configuration is required before export. Please configure the metadata to
        continue
        <Link
          sx={{ cursor: 'pointer', ml: 1 }}
          onClick={navigateToSystemConfiguration}
          fontWeight="600"
        >
          Configure Metadata
        </Link>
      </Typography>
    </Stack>
  );
}

function PicklistConfigurationStep({
  isPicklistConfigured,
  showPicklistValues,
  handleShowPicklistValues,
  navigateToSystemConfiguration,
  isRunQueryFlow,
}) {
  const [showPicklistAlert, setShowPicklistAlert] = useState(!isPicklistConfigured);

  const checkIfPicklistExist = useCallback(
    ({ currentTarget }) => {
      const status = currentTarget.dataset.showPicklist === 'true';

      if (status && !isPicklistConfigured) {
        setShowPicklistAlert(true);
        return;
      }

      setShowPicklistAlert(false);
    },
    [isPicklistConfigured]
  );
  if (isPicklistConfigured && isRunQueryFlow) {
    return (
      <Stack height="100%" alignItems="center" m={1}>
        <GenericSuccessIllustration width="200px" height="200px" />
        <Typography color="textSecondary" align="center">
          The system Picklist is configured properly. You're now ready to move on - please click the
          'Run' button to fetch data.
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack direction="column" mt={2}>
        {!isRunQueryFlow && isPicklistConfigured ? (
          <>
            <DialogContentText>Do you want to view picklist values?</DialogContentText>
            <RadioGroup
              row
              aria-label="viewPicklistValues"
              value={showPicklistValues}
              onChange={handleShowPicklistValues}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Yes"
                data-show-picklist={true}
                onClick={checkIfPicklistExist}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
                data-show-picklist={false}
                onClick={checkIfPicklistExist}
              />
            </RadioGroup>
          </>
        ) : null}
      </Stack>
      {showPicklistAlert && (
        <Stack height="100%" alignItems="center" m={1}>
          <NotFoundImage width="180px" height="200px" />
          <Typography color="textSecondary" align="center">
            The system linked to this connection doesn't have a configured picklist. Without
            configuration, the data will lack picklist values.
            <Link
              sx={{ cursor: 'pointer', ml: 1 }}
              onClick={navigateToSystemConfiguration}
              fontWeight="600"
            >
              Configure Picklist
            </Link>
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

function SheetCustomizationStep({ sheetNameAccessor, handleSheetNameChange, queryUrl }) {
  const sheetNameAccessorAdapter = sheetNameAccessor === 'entity_name' ? 'label' : 'name';

  const entityFromURL = extractEntityFromQueryString(queryUrl);
  const entitiesMap = useReportViewState((state) => {
    const forkedOptionsMap = state.fork.entity.optionsMap;

    if (!forkedOptionsMap) {
      return state.current.entity.optionsMap;
    }

    return forkedOptionsMap;
  });
  const selectedEntity = entitiesMap?.[entityFromURL];

  return (
    <Stack mt={4}>
      <DialogContentText>
        Choose a file naming convention for the exported excel file.
      </DialogContentText>
      <RadioGroup
        row
        aria-label="sheetNameType"
        value={sheetNameAccessor}
        onChange={handleSheetNameChange}
      >
        <FormControlLabel
          value="entity_code"
          control={<Radio />}
          label="Technical (Compatible with MDGEN)"
        />
        <FormControlLabel value="entity_name" control={<Radio />} label="User-friendly" />
      </RadioGroup>
      <Stack
        direction="row"
        p={1}
        pl={2}
        borderRadius={1}
        sx={{ backgroundColor: '#ededed' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        width="100%"
      >
        <Typography component="span" display="inline" fontFamily="monospace">
          The sheet name will be{' '}
          <span style={{ fontWeight: 600 }}>{selectedEntity?.[sheetNameAccessorAdapter]}</span>
        </Typography>
      </Stack>
    </Stack>
  );
}

function PasswordConfigurationStep({ setIsPasswordValid, setFilePassword, isRetriggerJobFlow }) {
  const [isPasswordValidating, setIsPasswordValidating] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false); //controls the error prop of text field

  const toggleShowPassword = useCallback(() => setShowPassword((show) => !show), []);

  const [handlePasswordChange] = useDebouncedCallback((currentValue) => {
    const isPasswordValid = currentValue.length > 5 && currentValue.trim() !== '';
    setIsPasswordValid(isPasswordValid); //controls the disability of create job button
    setIsInvalidPassword(!isPasswordValid);
    if (isPasswordValid) {
      setFilePassword(currentValue);
    }
    setIsPasswordValidating(false);
  }, 1000);

  return (
    <>
      <Stack direction="column" mt={isRetriggerJobFlow ? 0 : 4}>
        <DialogContentText>
          Please enter a password to protect your Excel file. This password will be required to open
          the file in the future.
        </DialogContentText>
        <Alert severity="warning" sx={{ marginTop: 1, marginBottom: 1 }}>
          Make sure to remember your password. If you lose or forget the password, you may not be
          able to access the contents of this file.
        </Alert>
        {isRetriggerJobFlow && (
          <Alert severity="info" sx={{ marginTop: 1, marginBottom: 1 }}>
            Picklist configuration and Sheet name customization steps have been prefilled using the
            expired job information.
          </Alert>
        )}
        <TextField
          {...commonTextFieldProps}
          sx={{ width: 400 }}
          size="small"
          label="enter a password"
          onChange={(event) => {
            setIsPasswordValidating(true);
            handlePasswordChange(event.target.value);
          }}
          error={isInvalidPassword}
          type={showPassword ? 'text' : 'password'}
          helperText={
            isInvalidPassword ? 'Password must be at least 6 characters and not empty' : ''
          }
          InputProps={{
            endAdornment: isPasswordValidating ? (
              <CircularProgress size={15} />
            ) : (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  edge="end"
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  );
}
