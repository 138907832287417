import { useRef } from 'react';
import { createPortal } from 'react-dom';

export function Portal({ children }) {
  const containerRef = useRef();

  if (!containerRef.current) {
    const div = document.createElement('div');
    div.setAttribute('id', '__onboarding-tour');
    document.body.appendChild(div);

    containerRef.current = div;
  }

  return createPortal(children, containerRef.current);
}
