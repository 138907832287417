import { createTheme } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';

/**
 * custom theme object for the material-ui components
 */
export const mui5Theme = createTheme({
  palette: {
    type: 'light',
    common: { black: '#000', white: '#fff' },
    primary: {
      light: 'rgba(77, 121, 166, 1)',
      main: 'rgba(53, 74, 95, 1)',
      dark: 'rgba(41, 52, 64, 1)',
      contrastText: 'rgba(231, 238, 246, 1)',
    },
    secondary: {
      light: 'rgba(122, 255, 225, 1)',
      main: 'rgba(30, 189, 152, 1)',
      dark: 'rgba(53, 138, 117, 1)',
      contrastText: 'rgba(224, 237, 233, 1)',
    },
    success: {
      light: 'rgba(76, 175, 80, 1)',
      main: 'rgba(46, 125, 50, 1)',
      dark: 'rgba(27, 94, 32, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    error: {
      light: 'rgba(249, 147, 150, 1)',
      main: 'rgba(255, 60, 65, 1)',
      dark: 'rgba(163, 7, 10, 1)',
      contrastText: 'rgba(252, 230, 232, 1)',
    },
    warning: {
      light: 'rgba(255, 152, 0, 1)',
      main: 'rgba(237, 108, 2, 1)',
      dark: 'rgba(230, 81, 0, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    integrtr: {
      green: '#08b44c',
    },
    background: {
      paper: '#fff',
      default: '#fff',
      navBar: 'rgba(53, 74, 95, 1)',
    },
    action: {
      disabledBackground: grey[300],
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: "'Inter', sans-serif",
    subtitle1: {
      fontWeight: 'bold',
      color: blueGrey[600],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          width: '100%',
        },
        body: {
          margin: 0,
          width: '100%',
          overflowX: 'hidden',
          fontWeight: 'normal',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        a: {
          textDecoration: 'inherit',
          color: 'inherit',
        },
        '.tp-dfwv': {
          position: 'fixed !important',
          bottom: '8px !important',
          top: 'unset !important',
        },
        '.wb-header': {
          backgroundColor: '#354A5F',
        },
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: 'darkgray lightgray',
        },
        '*::-webkit-scrollbar': {
          width: 12,
          height: 12,
        },
        '*::-webkit-scrollbar-track': {
          background: 'lightgray',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgray',
          borderRadius: 20,
          border: '3px solid lightgray',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    // TextField
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: blueGrey[50],
          borderBottomRightRadius: 4,
          borderBottomLeftRadius: 4,
        },
        '&.MuiFilledInput-underline': {
          '&:hover:before': {
            borderBottom: blueGrey[50],
          },
          '&:before': {
            borderBottom: blueGrey[50],
          },
        },
      },
    },
  },
});

/**
 * common styling variant props for the TextField
 */
export const commonTextFieldProps = {
  margin: 'dense',
  InputLabelProps: {
    shrink: true,
  },
  variant: 'filled',
};

export const borders = [
  '1px solid rgba(0, 0, 0, 0.12)',
  '1px solid rgba(224, 224, 224, 1)',
  `1px solid ${blueGrey[50]}`,
  '1px solid rgba(53, 74, 95, 1)',
];
