import { Autocomplete, Badge, Box, Button, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { MdCheck } from 'react-icons/md';

import { useDisclosure } from '../../hooks/useDisclosure';
import { useReportViewState, reportViewStateHandlers } from '../../state/reportView';
import { commonTextFieldProps } from '../../theme';
import { AutocompleteVirtualizedListbox } from '../AutocompleteVirtualizedListbox';
import {
  AutocompleteOption,
  autoCompleteFilterOptions,
} from '../FormComponents/AutocompleteOption';
import PopoverMenu from './PopoverMenu';

const entityStateSelector = (state) => ({
  entityOptions: state.fork.entity.options,
  selectedEntity: state.fork.entity.value,
});
export function SelectEntityOption({ schema, disabled }) {
  const ref = useRef();

  const { isOpen, open, close } = useDisclosure();

  const { selectedEntity, entityOptions } = useReportViewState(entityStateSelector);
  const { handleEntityChange } = reportViewStateHandlers;

  return (
    <>
      <Badge
        badgeContent={<MdCheck />}
        color="primary"
        invisible={!selectedEntity}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiBadge-badge': {
            height: '20px',
            width: '20px',
            p: 0,
            top: 5,
            left: 5,
          },
          ml: 1,
        }}
      >
        <Button
          ref={ref}
          onClick={open}
          size="small"
          variant="outlined"
          color="primary"
          disabled={disabled}
        >
          Entity
        </Button>
      </Badge>
      <PopoverMenu isOpen={isOpen} close={close} anchorEl={ref.current}>
        <Box minWidth={400} maxWidth={680} pr={2}>
          <Autocomplete
            fullWidth
            size="small"
            autoHighlight
            autoComplete
            openOnFocus
            disableListWrap
            ListboxComponent={AutocompleteVirtualizedListbox}
            options={entityOptions}
            filterOptions={autoCompleteFilterOptions}
            isOptionEqualToValue={(option, value) =>
              option.name === value.name && option.namespace === value.namespace
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Select Entity" {...commonTextFieldProps} />
            )}
            renderOption={(props, option, { inputValue }) => (
              <li {...props}>
                <AutocompleteOption
                  label={option.label}
                  name={option.name}
                  inputValue={inputValue}
                />
              </li>
            )}
            value={selectedEntity}
            onChange={handleEntityChange(schema)}
          />
        </Box>
      </PopoverMenu>
    </>
  );
}
