import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import { entries, pick, values } from 'lodash-es';

import { useReportViewState } from 'state/reportView';
import useColumns from './useColumns';
import { flattenNMutiplicity } from './utils';
import MassUpdateDialog from '../MassUpdateDialog';
import { PAGE_SIZE } from '../constants';
import { createStore } from '@halka/state/dist';

export const dataGridStore = createStore([]);

const visibleFieldsSelector = (state) => state.current.visibleFields.value;
const visibleFieldsOptionsSelector = (state) => state.current.visibleFields.optionsMap;
export default function TableView({
  isEditMode,
  setDataToUpsert,
  baseEndpoint,
  fetchPaginatedDataFromDatabase,
  updateColumnsToEdit,
  openEditProgressDialog,
  handleSaveData,
  batchState,
  isFullScreen,
}) {
  const { classes } = useStyles({ isEditMode });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const [massUpdateDialogState, setMassUpdateDialogState] = useState(false);

  const [massUpdateDialogInformation, setMassUpdateDialogInformation] = useState({});

  const handleMassUpdateDialogClose = useCallback(() => {
    setMassUpdateDialogState(false);
  }, []);

  const [massUpdateValue, setMassUpdateValue] = useState(null);

  const visibleFieldsValue = useReportViewState(visibleFieldsSelector);
  const visibleFieldsOptionsMap = useReportViewState(visibleFieldsOptionsSelector);

  const [columns, nestedFields, hasParent, picklistValues, picklistFetcher] = useColumns({
    visibleFieldsValue,
    visibleFieldsOptionsMap,
    isEditMode,
    setMassUpdateDialogState,
    baseEndpoint,
    setMassUpdateDialogInformation,
  });

  const visibleColumns = useMemo(() => columns.filter(({ visible }) => visible), [columns]);
  const keyColumns = useMemo(
    () => visibleColumns.filter(({ isKey, parent }) => isKey && !parent),
    [visibleColumns]
  );
  const keyNames = useMemo(() => keyColumns.map(({ key, parent }) => key), [keyColumns]);

  useEffect(() => {
    if (massUpdateValue !== null) {
      rows.forEach((obj) => {
        if (obj.hasOwnProperty(massUpdateDialogInformation.name)) {
          obj[massUpdateDialogInformation.name] = massUpdateValue;
        }
      });

      updateColumnsToEdit(massUpdateDialogInformation.name);
      setDataToUpsert(rows);
      openEditProgressDialog();
    }
    setMassUpdateValue(null);
  }, [
    massUpdateValue,
    massUpdateDialogInformation,
    rows,
    openEditProgressDialog,
    updateColumnsToEdit,
    setDataToUpsert,
    handleSaveData,
  ]);

  // For every data upsert the key columns should be added as columnsToEdit because the key values
  // are reuired to identify which row is supposed to be editted
  useEffect(() => {
    if (keyNames.length <= 0) {
      return;
    }

    // Before setting the new key columns, we need to reset the older values
    updateColumnsToEdit([], true);
    keyNames.forEach((columnKey) => updateColumnsToEdit(columnKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyNames]);

  const cellState = dataGridStore.get();

  useEffect(() => {
    const fetchFirstPage = async () => {
      const { data: newRows } = await fetchPaginatedDataFromDatabase(0, true);

      if (!newRows) {
        return;
      }

      const processedRows = processRows(newRows, nestedFields);
      setRows(processedRows);
    };

    fetchFirstPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processRows, isEditMode]);

  const handleRowChange = useCallback(
    (updatedRows, { indexes, column }) => {
      indexes.forEach((index) => {
        const previousData = rows[index];
        const newData = updatedRows[index];

        // We are generating a data-key from all the primaryKey values to ensure that
        // for edits in a single row - multiple entries are not pushed into dataToUpsert state
        // For example: if [row1][column1], [row1][column2] and [row1][column3] are updated
        // they should be registered as a single entry
        const keyValues = pick(newData, keyNames);
        const dataKey = values(keyValues).join('-');

        const sanitizedData = entries(newData).reduce((acc, [key, value]) => {
          if (!value) {
            return acc;
          }

          acc[key] = value;
          return acc;
        }, {});

        setDataToUpsert((state) => ({ ...state, [dataKey]: sanitizedData }));
        updateColumnsToEdit(column.key);

        const colIndex = column.idx + 1;
        const rowIndex = index + 2;

        const cellSelector = `div[aria-rowindex="${rowIndex}"] > div[aria-colindex="${colIndex}"]`;
        const cell = { selector: cellSelector, rowIndex, colIndex, previousData, newData };
        dataGridStore.set((state) => [...state, cell]);

        setTimeout(() => {
          const element = document.querySelector(cellSelector);

          if (!element) return;

          element.style.backgroundColor = '#b1fce1';
        });
      });

      setRows(updatedRows);
    },
    [keyNames, rows, setDataToUpsert, updateColumnsToEdit]
  );

  const checkIsAtBottom = ({ currentTarget }) => {
    return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;
  };

  const handleScroll = useCallback(
    async (event) => {
      try {
        cellState.forEach((cell) => {
          const element = document.querySelector(cell.selector);

          if (!element) return;

          element.style.backgroundColor = '#b1fce1';
        });

        setError(false);

        if (rows.length === batchState.totalRecords) {
          return;
        }

        if (isLoading || !checkIsAtBottom(event)) {
          return;
        }

        setIsLoading(true);

        const page = rows.length / PAGE_SIZE;

        const { data: newRows, error } = await fetchPaginatedDataFromDatabase(page, true);

        if (error) {
          setError(error);
          setIsLoading(false);
        }

        const processedRows = processRows(newRows, nestedFields);

        setRows((state) => [...state, ...processedRows]);
        setIsLoading(false);
      } catch (err) {
        setError(err?.message ?? err.toString());
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, processRows, rows, nestedFields]
  );

  return (
    <>
      {massUpdateDialogState && (
        <MassUpdateDialog
          isOpen={massUpdateDialogState}
          handleClose={handleMassUpdateDialogClose}
          rowsCount={rows.length}
          massUpdateDialogInformation={massUpdateDialogInformation}
          setMassUpdateValue={setMassUpdateValue}
          picklistValues={picklistValues[massUpdateDialogInformation.picklist] ?? []}
          totalRecordsCount={batchState.totalRecords}
          picklistFetcher={picklistFetcher}
        />
      )}

      <Stack
        width="100%"
        height={isFullScreen ? '100%' : 'calc(100vh - 275px)'}
        px={isEditMode ? 1 : 2}
        pb={isEditMode ? 1 : 2}
      >
        <Box
          id="_INT_RE__TABLE_CONTAINER"
          width="100%"
          height="100%"
          className={classes.tableContainer}
          {...(isEditMode && { border: 2, borderRadius: 1, p: 1 })}
        >
          <DataGrid
            columns={visibleColumns}
            rows={rows ?? []}
            className={`${classes.table} fill-grid rdg-light`}
            // Show the parent of the nested fields in the top row of the heading if present.
            headerRowHeight={hasParent ? 80 : 40}
            rowHeight={40}
            onRowsChange={handleRowChange}
            enableVirtualization={true}
            onScroll={handleScroll}
            onCellClick={(args) => {
              const { column, selectCell } = args;
              if (column.picklist) {
                selectCell(true);
              }
            }}
          />
          {(isLoading || Boolean(error)) && (
            <Stack
              direction="row"
              position="absolute"
              p={1}
              justifyContent="center"
              alignItems="center"
              bottom={10}
              width="98.4%"
              spacing={1}
              sx={(theme) => ({
                backgroundColor: Boolean(error)
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
                color: theme.palette.common.white,
              })}
            >
              {isLoading && (
                <>
                  <Typography>Loading more data...</Typography>
                  <CircularProgress size={15} sx={{ color: 'white' }} />
                </>
              )}

              {Boolean(error) && <Typography>Failed to fetch data, please try again!.</Typography>}
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  );
}

const useStyles = makeStyles()((theme, { isEditMode }) => ({
  tableContainer: {
    '& .merged-cell': {
      borderTop: 0,
      borderBottom: 0,
      backgroundColor: theme.palette.common.white,
    },

    '& .merged-first-cell': {
      borderTop: `1px solid #ddd !important`,
    },

    '& .text-center': {
      textAlign: 'center',
    },

    '& .cell': {
      borderTop: 0,
      borderBottom: 0,
      userSelect: 'text',
      backgroundColor: 'transparent',
    },
  },
  table: {
    height: '100%',
    '& .rdg-header-row > .rdg-cell': {
      padding: 0,
    },
    '& .rdg-row-even > .rdg-cell ': {
      backgroundColor: theme.palette.grey[200],
    },
    '& .rdg-row-odd > .rdg-cell ': {
      backgroundColor: theme.palette.grey[100],
    },
    '& .rdg-row > .rdg-cell-frozen': {
      backgroundColor: theme.palette.common.white,
    },
    '& .rdg-cell': {
      display: 'flex',
      alignItems: 'center',
    },
    ...(isEditMode && {
      '& .rdg-row > div[aria-selected="true"]': {
        outline: `2px solid #000`,
      },
    }),
  },
}));

const processRows = (data, nestedFields) => {
  return data
    .map((it) =>
      flattenNMutiplicity({
        obj: it,
        nestedFields,
      })
    )
    .reduce((acc, cur) => {
      acc.push(...cur);
      return acc;
    }, []);
};
