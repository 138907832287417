import {
  Autocomplete,
  Badge,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DateTimePicker } from 'components/FormComponents/DatePicker';
import { DebouncedTextField } from 'components/FormComponents/DebouncedTextField';
import { TimePicker } from 'components/FormComponents/TimePicker';
import { customFormats } from 'date';
import { borders } from 'mui5Theme';
import React, { useCallback, useMemo, useRef } from 'react';
import { MdAdd, MdDelete, MdVpnKey } from 'react-icons/md';
import { useDisclosure } from '../../hooks/useDisclosure';

import { canQueryDataType, hintTexts } from '../../odata/queryBuilder';
import { typeBasedFilterOptions } from '../../odata/reportView';
import { ODATA_DATA_TYPES } from '../../odata/utils';
import { OPERATORS, WHERE_CONDITION } from '../../state/queryBuilder';
import { reportViewStateHandlers, useReportViewState } from '../../state/reportView';
import { commonTextFieldProps } from '../../theme';
import { AutocompleteVirtualizedListbox } from '../AutocompleteVirtualizedListbox';
import {
  autoCompleteFilterOptions,
  AutocompleteOption,
} from '../FormComponents/AutocompleteOption';
import PopoverMenu from './PopoverMenu';

const whereSliceSelector = (state) => state.fork.where;
const entityValueSelector = (state) => state.fork.entity.value;
export function FilterOption({ isSfSystem, disabled }) {
  const ref = useRef();

  const { isOpen, open, close } = useDisclosure();

  const entityValue = useReportViewState(entityValueSelector);
  const { options, value } = useReportViewState(whereSliceSelector);

  const numberOfFilters = getFilterCount(value.rules);

  return (
    <>
      <Badge
        badgeContent={numberOfFilters}
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiBadge-badge': {
            height: '20px',
            width: '20px',
            p: 0,
            top: 5,
            left: 5,
          },
          ml: 1,
        }}
      >
        <Button
          disabled={!entityValue || disabled}
          ref={ref}
          onClick={open}
          size="small"
          variant="outlined"
          color="primary"
        >
          Filter
        </Button>
      </Badge>
      <PopoverMenu isOpen={isOpen} close={close} anchorEl={ref.current}>
        <Box minWidth={680} maxHeight={300} p={1} sx={{ overflowX: 'hidden', overFlowY: 'auto' }}>
          {options.length ? (
            <Group
              key={value.id}
              group={value}
              path={WHERE_CONDITION.BASE_PATH}
              isSfSystem={isSfSystem}
            />
          ) : (
            <Typography
              variant="overline"
              sx={(theme) => ({
                m: 1,
                color: theme.palette.grey[800],
              })}
            >
              No filterable properties on this entity
            </Typography>
          )}
        </Box>
      </PopoverMenu>
    </>
  );
}

const Group = ({ path, group, isSfSystem }) => {
  const {
    handleAddNewRule,
    handleToggleGroupOperator,
    handleAddNewGroup,
    handleDeleteRuleOrGroup,
  } = reportViewStateHandlers;

  return (
    <Stack
      spacing={1}
      width="100%"
      {...(path !== WHERE_CONDITION.BASE_PATH && {
        sx: (theme) => ({
          borderLeft: borders[2],
          borderLeftWidth: theme.spacing(1),
          borderRadius: 1,
          m: 1,
        }),
      })}
    >
      <Stack
        direction="row"
        width="100%"
        spacing={1}
        ml={path === WHERE_CONDITION.BASE_PATH ? 0 : 1}
        alignItems="center"
      >
        <ToggleButtonGroup
          exclusive
          value={group.operator}
          aria-label="group operator"
          size="medium"
          onChange={handleToggleGroupOperator(path)}
          sx={(theme) => ({
            width: theme.spacing(10),
            height: theme.spacing(3.5),
          })}
        >
          <ToggleButton
            value={OPERATORS.AND}
            aria-label="AND operator"
            sx={(theme) => ({
              width: '100%',
              fontSize: 10,
              fontWeight: 600,

              '&.Mui-selected': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
              },
            })}
          >
            And
          </ToggleButton>
          <ToggleButton
            value={OPERATORS.OR}
            aria-label="AND operator"
            sx={(theme) => ({
              width: '100%',
              fontSize: 10,
              fontWeight: 600,

              '&.Mui-selected': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
              },
            })}
          >
            Or
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          size="small"
          startIcon={<MdAdd />}
          variant="outlined"
          color="primary"
          onClick={handleAddNewGroup(path)}
        >
          Group
        </Button>

        <Button
          size="small"
          startIcon={<MdAdd />}
          variant="outlined"
          color="primary"
          onClick={handleAddNewRule(path, WHERE_CONDITION.TYPE.GROUP)}
        >
          Condition
        </Button>
        {path !== WHERE_CONDITION.BASE_PATH && (
          <Box>
            <IconButton size="small" onClick={handleDeleteRuleOrGroup(path)}>
              <MdDelete />
            </IconButton>
          </Box>
        )}
      </Stack>

      <Stack spacing={1}>
        {Boolean(group.rules.length) &&
          group.rules.map((rule, index) => {
            const nextPath = `${path}.rules[${index}]`;

            if (rule.rules) {
              return (
                <Box key={rule.id}>
                  <Group path={nextPath} group={rule} />
                </Box>
              );
            }

            return (
              <Box key={rule.id}>
                <Rule
                  path={nextPath}
                  rule={rule}
                  isLast={index + 1 === group.rules.length}
                  isSfSystem={isSfSystem}
                />
              </Box>
            );
          })}
      </Stack>
    </Stack>
  );
};

const Rule = ({ rule, path, isLast, isSfSystem }) => {
  const { options: whereOptions } = useReportViewState(whereSliceSelector);

  const {
    handleAddNewRule,
    handleUpdateRuleProperty,
    handleUpdateRuleFilter,
    handleUpdateRuleParam,
    handleDeleteRuleOrGroup,
  } = reportViewStateHandlers;

  return (
    <Stack direction="row" spacing={1} ml={1} alignItems="center">
      <Autocomplete
        fullWidth
        size="small"
        disableClearable
        autoHighlight
        autoComplete
        openOnFocus
        disableListWrap
        ListboxComponent={AutocompleteVirtualizedListbox}
        options={whereOptions}
        filterOptions={autoCompleteFilterOptions}
        getOptionLabel={({ name }) => name}
        getOptionDisabled={({ type, filterable }) => !canQueryDataType(type) || !filterable}
        renderInput={(params) => (
          <TextField {...params} label="Property" {...commonTextFieldProps} />
        )}
        renderOption={(props, option, { inputValue }) => (
          <li {...props}>
            <AutocompleteOption
              label={option.filterable ? option.label : `${option.label} (Not Filterable)`}
              name={option.name}
              inputValue={inputValue}
              adornment={option.isKey && !option.name.includes('/') ? <MdVpnKey /> : null}
            />
          </li>
        )}
        value={rule.property}
        onChange={handleUpdateRuleProperty(path)}
        sx={{ width: '320px' }}
      />

      <Autocomplete
        fullWidth
        size="small"
        disableClearable
        autoHighlight
        autoComplete
        openOnFocus
        options={typeBasedFilterOptions[rule.property.type]}
        isOptionEqualToValue={({ key }, value) => key === value.key}
        getOptionDisabled={(options) => options.disabled?.({ isSfSystem })}
        getOptionLabel={({ label }) => label}
        renderOption={(props, option, { inputValue }) => (
          <li {...props}>
            <AutocompleteOption name={option.label} inputValue={inputValue} />
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Filter Operator" {...commonTextFieldProps} />
        )}
        value={rule.filter}
        onChange={handleUpdateRuleFilter(path)}
        sx={{ width: '320px' }}
      />
      {rule.property.type !== ODATA_DATA_TYPES['Edm.Boolean'] && (
        <Stack width="320px">
          <FieldPicker
            key={rule.property.name}
            rule={rule}
            updateRuleParam={handleUpdateRuleParam(path)}
          />
        </Stack>
      )}

      <Box>
        <IconButton size="small" onClick={handleDeleteRuleOrGroup(path)}>
          <MdDelete />
        </IconButton>
      </Box>

      {isLast && (
        <Box>
          <IconButton size="small" onClick={handleAddNewRule(path, WHERE_CONDITION.TYPE.RULE)}>
            <MdAdd />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
};

export const FieldPicker = ({ rule, updateRuleParam }) => {
  const hints = useMemo(() => rule.filter.getHints?.(rule), [rule]);
  const validate = useCallback(
    (value) => rule.filter.validate?.({ ...rule, param: value }),
    [rule]
  );

  const isDateOrTimeClearable = useMemo(
    () =>
      (rule.filter.key.endsWith('timeEq') || rule.filter.key.endsWith('timeNe')) &&
      rule.property.nullable === 'true',
    [rule]
  );

  if (
    (rule.property.type === ODATA_DATA_TYPES['Edm.DateTime'] ||
      rule.property.type === ODATA_DATA_TYPES['Edm.DateTimeOffset']) &&
    rule.filter?.key.startsWith('datetime')
  ) {
    return (
      <DateTimePicker
        label="Parameter Value"
        clearable={isDateOrTimeClearable}
        value={rule.param || null}
        onChange={updateRuleParam}
        dateFormat={customFormats.datetime}
        placeholder={hintTexts.datetime.placeholder}
        hintTexts={hintTexts}
        isMUI5={true}
      />
    );
  }

  if (rule.property.type === ODATA_DATA_TYPES['Edm.Time'] && rule.filter?.key.startsWith('time')) {
    return (
      <TimePicker
        isMUI5={true}
        label="Parameter Value"
        clearable={isDateOrTimeClearable}
        value={rule.param || null}
        updateRuleParam={updateRuleParam}
        {...hintTexts.time}
      />
    );
  }

  return (
    <DebouncedTextField
      key={rule.property.name + rule.filter.key}
      label="Parameter Value"
      {...commonTextFieldProps}
      {...hints}
      validate={rule.filter.validate && validate}
      value={rule.param}
      onChange={updateRuleParam}
      isMUI5={true}
      sx={{ width: '320px' }}
      size="small"
    />
  );
};

const getFilterCount = (rules) => {
  let newCount = 0;

  for (let ruleOrGroup of rules) {
    if (ruleOrGroup.rules) {
      newCount += getFilterCount(ruleOrGroup.rules);
    } else {
      newCount++;
    }
  }

  return newCount;
};
