import React, { useCallback, useState } from 'react';
import { usePromise } from 'react-use';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { MdError, MdCheckCircle } from 'react-icons/md';
import { AiFillThunderbolt } from 'react-icons/ai';
import { useQueryClient } from 'react-query';

import { PING_TEST, SYSTEMS } from '../constants';
import { dataflowApiBase, getServiceInstance } from '../service';
import { useTenantState } from '../data/user';
import { tourSteps } from '../components/Onboarding/Tour';

export const usePingTest = (selectedConnection) => {
  const defaultStatus = PING_TEST[selectedConnection?.last_ping_state] ?? PING_TEST.NEUTRAL;

  const [status, updateStatus] = useState(defaultStatus);
  const [isPingingSystem, updateIsPingingSystem] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updateErrorMessage = useCallback((error) => {
    if (error?.status === 404) {
      setErrorMessage('Invalid Endpoint');
    } else {
      setErrorMessage(error?.msg);
    }
  }, []);

  const queryClient = useQueryClient();

  const tenant = useTenantState();

  const mounted = usePromise();

  const runPingTest = useCallback(
    async (url, setFinalPingStatus) => {
      updateIsPingingSystem(true);
      setTimeout(async () => {
        try {
          // For SF Systems, we make a HEAD request first and for others we make a GET request
          const response = await mounted(
            getServiceInstance(tenant.tenant_id).post(
              `${dataflowApiBase}/connection/${selectedConnection.connection_id}/proxy`,
              {
                url,
                type: 'xml',
                method:
                  selectedConnection.user_system.system === SYSTEMS.SF_EC.KEY ? 'HEAD' : 'GET',
              }
            )
          );
          if (response.includes('BizX') && response.includes('Unable to map')) {
            setFinalPingStatus(PING_TEST.FAILURE);
            updateIsPingingSystem(false);
          } else {
            setFinalPingStatus(PING_TEST.SUCCESS);
            queryClient.removeQueries([url, selectedConnection.connection_id, tenant.tenant_id], {
              exact: true,
            });
            queryClient.invalidateQueries([
              '/connection?populateUserSystem=true',
              tenant.tenant_id,
            ]);
            updateIsPingingSystem(false);
          }

          tourSteps['ping-test'].next({ isConnectionLive: true });
        } catch (error) {
          // If the HEAD request fails for the SF System, we then re-try using a GET request because the HEAD
          // request doesn't give any error response
          if (selectedConnection.user_system.system === SYSTEMS.SF_EC.KEY) {
            try {
              await mounted(
                getServiceInstance(tenant.tenant_id).post(
                  `${dataflowApiBase}/connection/${selectedConnection.connection_id}/proxy`,
                  {
                    url,
                    type: 'xml',
                  }
                )
              );
              queryClient.removeQueries([url, selectedConnection.connection_id, tenant.tenant_id], {
                exact: true,
              });
              queryClient.invalidateQueries([
                '/connection?populateUserSystem=true',
                tenant.tenant_id,
              ]);
              updateStatus(PING_TEST.SUCCESS);
              updateIsPingingSystem(false);

              tourSteps['ping-test'].next({ isConnectionLive: true });
            } catch (error) {
              queryClient.removeQueries([url, selectedConnection.connection_id, tenant.tenant_id], {
                exact: true,
              });
              queryClient.invalidateQueries([
                '/connection?populateUserSystem=true',
                tenant.tenant_id,
              ]);
              updateErrorMessage(error);
              updateStatus(PING_TEST.FAILURE);
              updateIsPingingSystem(false);

              tourSteps['ping-test'].next({ isConnectionLive: false });
            }
          }
          queryClient.removeQueries([url, selectedConnection.connection_id, tenant.tenant_id], {
            exact: true,
          });
          queryClient.invalidateQueries(['/connection?populateUserSystem=true', tenant.tenant_id]);
          // Here, we are not updating the error message in case of an OData service connection
          // This is because the error response in those cases don't follow any standards
          updateStatus(PING_TEST.FAILURE);
          updateIsPingingSystem(false);

          tourSteps['ping-test'].next({ isConnectionLive: false });
        }
      });
    },
    [tenant, selectedConnection, mounted, queryClient, updateErrorMessage]
  );

  return {
    status,
    updateStatus,
    isPingingSystem,
    runPingTest,
    errorMessage,
  };
};

export const getPingTestButtonIcons = (isPingingSystem, pingTestStatus, isFormDirty) => {
  if (isPingingSystem) {
    return <CircularProgress size={15} />;
  } else if (pingTestStatus === PING_TEST.FAILURE && !isFormDirty) {
    return <MdError />;
  } else if (pingTestStatus === PING_TEST.SUCCESS && !isFormDirty) {
    return <MdCheckCircle />;
  }

  return <AiFillThunderbolt />;
};

export const usePingTestStateStyles = makeStyles((theme) => ({
  [PING_TEST.SUCCESS]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  [PING_TEST.FAILURE]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    arrow: {
      color: theme.palette.error.main,
    },
  },
  [PING_TEST.NEUTRAL]: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

export const usePingTestStateHoverStyles = makeStyles((theme) => ({
  [PING_TEST.FAILURE]: {
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  [PING_TEST.SUCCESS]: {
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  [PING_TEST.NEUTRAL]: {
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

export const usePingTestTooltipStyles = makeStyles((theme) => ({
  FAILURE: {
    color: theme.palette.error.main,
  },
}));
