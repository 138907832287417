import buildQuery from 'odata-query';
import memoizeOne from 'memoize-one';
import dayjs from 'dayjs';

import { getEntitySetName, ODATA_DATA_TYPES } from './utils';
import { filterOptions, memoizedCreateFilterObject } from './queryBuilder';
import { checkIsVariableValid } from 'hooks/useDateTimePicker';
import { ROOT_LEVEL_GROUP_NAME } from 'components/ReportViewComponents/constants';

const createSelectObject = (columnSelect) => {
  if (columnSelect.length === 0) {
    return;
  }

  const selectedColumns = columnSelect.map((value) => {
    const [parent, propertyName] = value.split('.');

    if (parent === ROOT_LEVEL_GROUP_NAME) {
      return propertyName;
    }

    return `${parent}/${propertyName}`;
  });

  return selectedColumns.length ? selectedColumns : null;
};

const memoizedCreateSelectObject = memoizeOne(createSelectObject);

const createExpandQueryString = (selectedExpandColumns) => {
  if (selectedExpandColumns.length === 0) {
    return '';
  }

  return selectedExpandColumns.join(',');
};
const memoizedCreateExpandQueryString = memoizeOne(createExpandQueryString);

export const createMinimalQuery = ({
  schema,
  entity,
  asOfDate,
  fromDate,
  toDate,
  where,
  expand,
  isSfSystem,
  columnSelect,
}) => {
  const filter = memoizedCreateFilterObject(where);
  const expandQuery = memoizedCreateExpandQueryString(expand);
  const select = memoizedCreateSelectObject(columnSelect);

  let queryString = buildQuery({
    top: 10,
    skip: 0,
    filter,
    select,
    expand: expandQuery,
  });

  if (isSfSystem) {
    queryString += embeddEffectiveRange(asOfDate, fromDate, toDate);
  }

  return `${getEntitySetName({
    schema,
    entity,
  })}${queryString}`;
};

/**
 * Function takes in a value and returns a relevant effective range value for the query
 *
 * @param {string|Object} value the datetime value or variable
 * @returns
 */
function getEffectiveRangeValue(value) {
  if (!value) {
    return;
  }

  // If the value is a datetime object or datetime string, then we check if it
  // is a valid date. If yes, we return the date after wrapping it with dayjs() to
  // ensure a valid datetime object is returned.
  if (dayjs(value).isValid()) {
    return dayjs(value);
  }

  // If the value is a string, we return the string value directly
  if (typeof value === 'string') {
    return value;
  }

  // If none of the above conditions match, it means the value is a variable object - $TODAY$
  // So we need to extract the key and return it
  return value?.key;
}

/**
 * This function returns the effective range query
 *
 * @param {Object|string} asOfDate
 * @param {Object|string} fromDate
 * @param {Object|string} toDate
 * @returns
 */
export function embeddEffectiveRange(asOfDate, fromDate, toDate) {
  let effectiveRangeString = '';

  const _asOfDate = getEffectiveRangeValue(asOfDate);
  const _fromDate = getEffectiveRangeValue(fromDate);
  const _toDate = getEffectiveRangeValue(toDate);

  // embeddEffectiveRange is a ultity function which can either get an object or string as a param
  // If it is a string, we embedd it as is. If it is an object - it must be a date object so we format
  // the date object and embedd the returned string to the query-string.
  if (_asOfDate) {
    if (checkIsVariableValid(_asOfDate)) {
      effectiveRangeString += `&asOfDate=${_asOfDate}`;
    } else {
      effectiveRangeString += `&asOfDate=${_asOfDate.format('YYYY-MM-DD')}`;
    }

    return effectiveRangeString;
  }

  if (_fromDate) {
    if (checkIsVariableValid(_fromDate)) {
      effectiveRangeString += `&fromDate=${_fromDate}`;
    } else {
      effectiveRangeString += `&fromDate=${_fromDate.format('YYYY-MM-DD')}`;
    }
  }

  if (_toDate) {
    if (checkIsVariableValid(_toDate)) {
      effectiveRangeString += `&toDate=${_toDate}`;
    } else {
      effectiveRangeString += `&toDate=${_toDate.format('YYYY-MM-DD')}`;
    }
  }

  return effectiveRangeString;
}

/**
 * an object map that contains the list of filter options supported for a data type
 */
export const typeBasedFilterOptions = {
  [ODATA_DATA_TYPES['Edm.Boolean']]: [filterOptions.isTrue, filterOptions.isFalse],
  [ODATA_DATA_TYPES['Edm.Int16']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
  ],
  [ODATA_DATA_TYPES['Edm.Int32']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
  ],
  [ODATA_DATA_TYPES['Edm.Int64']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
  ],
  [ODATA_DATA_TYPES['Edm.Single']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
    filterOptions.roundEq,
    filterOptions.floorEq,
    filterOptions.ceilingEq,
  ],
  [ODATA_DATA_TYPES['Edm.Double']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
    filterOptions.roundEq,
    filterOptions.floorEq,
    filterOptions.ceilingEq,
  ],
  [ODATA_DATA_TYPES['Edm.Decimal']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
    filterOptions.roundEq,
    filterOptions.floorEq,
    filterOptions.ceilingEq,
  ],
  [ODATA_DATA_TYPES['Edm.String']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.in,
    filterOptions.caseInsensitiveEq,
    filterOptions.caseInsensitiveNe,
    filterOptions.startsWith,
    filterOptions.notStartsWith,
    filterOptions.endsWith,
    filterOptions.notEndsWith,
    filterOptions.contains,
    filterOptions.notContains,
  ],
  [ODATA_DATA_TYPES['Edm.Guid']]: [filterOptions.guidEq, filterOptions.guidNe],
  [ODATA_DATA_TYPES['Edm.DateTime']]: [
    filterOptions.datetimeEq,
    filterOptions.datetimeNe,
    filterOptions.datetimeEqOrBefore,
    filterOptions.datetimeBefore,
    filterOptions.datetimeAfter,
    filterOptions.datetimeEqOrAfter,
  ],
  [ODATA_DATA_TYPES['Edm.DateTimeOffset']]: [
    filterOptions.datetimeEq,
    filterOptions.datetimeNe,
    filterOptions.datetimeEqOrBefore,
    filterOptions.datetimeBefore,
    filterOptions.datetimeAfter,
    filterOptions.datetimeEqOrAfter,
  ],
  [ODATA_DATA_TYPES['Edm.Time']]: [
    filterOptions.timeEq,
    filterOptions.timeNe,
    filterOptions.timeEqOrBefore,
    filterOptions.timeBefore,
    filterOptions.timeAfter,
    filterOptions.timeEqOrAfter,
  ],
};
