/**
 * @file
 *
 * The base component that is used for initializing all the routes, context providers, etc.
 */
import DayjsUtils from '@date-io/dayjs';
import { CssBaseline, makeStyles, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConfirmationDialogProvider } from 'material-ui-confirmation';
import React, { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { GlobalErrorFallback } from './components/GlobalErrorFallback';
import { Tour } from './components/Onboarding/Tour';
import TabIndexedStorageContextProvider from './components/TabIndexedStorageContext';
import { CONFIG } from './config';
import { Connection } from './pages/Connection';
import { ConnectionsListing } from './pages/ConnectionsListing';
import { Error } from './pages/Error';
import { Home } from './pages/Home';
import { queryClient } from './queryClient';
import { getAppEnv } from './service';
import { theme } from './theme';

const AuthTokenInjector = lazy(() => import('./devOnly/AuthTokenInjector'));

if (CONFIG.ENV === 'production') {
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    release: CONFIG.RELEASE,
    environment: getAppEnv(),

    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      height: '100%',
      width: '100%',
    },
    body: {
      margin: 0,
      width: '100%',
      overflowX: 'hidden',
      fontWeight: theme.typography.fontWeightRegular,
    },
    '#root': {
      width: '100%',
      height: '100%',
    },
    a: {
      textDecoration: 'inherit',
      color: 'inherit',
    },
    '.tp-dfwv': {
      position: 'fixed !important',
      bottom: '8px !important',
      top: 'unset !important',
    },
    '.wb-header': {
      backgroundColor: '#354A5F',
    },
  },
  routerContainer: {
    height: '100%',
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <TabIndexedStorageContextProvider>
      <Router className={classes.routerContainer}>
        <ErrorBoundary FallbackComponent={GlobalErrorFallback}>
          <QueryClientProvider client={queryClient} contextSharing>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <Toaster position="bottom-left" toastOptions={{ duration: 4000 }} reverseOrder />
                <ConfirmationDialogProvider>
                  <CssBaseline />
                  <Tour />
                  <Switch>
                    <Route path="/t/:tenantId/connection/:connectionId">
                      <Connection />
                    </Route>
                    <Route path="/t/:tenantId">
                      <ConnectionsListing />
                    </Route>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route path="*">
                      <Error />
                    </Route>
                  </Switch>
                </ConfirmationDialogProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </Router>
      {CONFIG.ENV === 'development' && (
        <Suspense fallback={null}>
          <AuthTokenInjector />
        </Suspense>
      )}
    </TabIndexedStorageContextProvider>
  );
}

// TODO: use this for dark theme
// function ThemeWrapper({ children }) {
//   // const { theme } = useContext(ThemeSwitcherContext);

//   return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
// }
