import React, { useRef, useState } from 'react';
import {
  Alert,
  Badge,
  Box,
  Button,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { MdChevronRight, MdClose, MdKeyboardArrowDown } from 'react-icons/md';
import { get } from 'lodash-es';

import { reportViewStateHandlers, useReportViewState } from '../../state/reportView';
import { useDisclosure } from '../../hooks/useDisclosure';
import { useFilterFields, VirtualTree } from './VirtualTree';
import PopoverMenu from './PopoverMenu';
import { commonTextFieldProps } from 'mui5Theme';

export const MULTIPLICITY = {
  '*': {
    KEY: '*',
    LABEL: 'Relations with N Multiplicity',
  },
  1: {
    KEY: '1',
    LABEL: 'Relations with 1 Multiplicity',
  },
};

const expandSliceSelector = (state) => state.fork.expand;
const entityValueSelector = (state) => state.fork.entity.value;
export function ExpandOption({ schema, disabled }) {
  const ref = useRef();

  const entityValue = useReportViewState(entityValueSelector);
  const {
    options: expandFieldOptions,
    value: expandFieldValues,
    optionsMap: expandFieldOptionsMap,
  } = useReportViewState(expandSliceSelector);

  const { isOpen, open, close } = useDisclosure();

  return (
    <>
      <Tooltip title={expandFieldOptions.length === 0 ? 'No properties to expand' : ''}>
        <Badge
          badgeContent={expandFieldValues.length}
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiBadge-badge': {
              height: '20px',
              width: '20px',
              p: 0,
              top: 5,
              left: 5,
            },
            ml: 1,
          }}
        >
          <Button
            disabled={!entityValue || expandFieldOptions.length === 0 || disabled}
            ref={ref}
            onClick={open}
            size="small"
            variant="outlined"
            color="primary"
          >
            Expand
          </Button>
        </Badge>
      </Tooltip>
      <PopoverMenu isOpen={isOpen} close={close} anchorEl={ref.current} sx={{ pr: 0 }}>
        <ExpandFieldsPopperContent
          schema={schema}
          expandFieldOptions={expandFieldOptions}
          expandFieldValues={expandFieldValues}
          expandFieldOptionsMap={expandFieldOptionsMap}
        />
      </PopoverMenu>
    </>
  );
}

function ExpandFieldsPopperContent({
  schema,
  expandFieldOptions,
  expandFieldValues,
  expandFieldOptionsMap,
}) {
  const [showAlert, setShowAlert] = useState(() =>
    checkIfOneNMultiplicityPropertyIsSelected(expandFieldValues, expandFieldOptionsMap)
  );

  const { inputValue, groupedFields, handleInputChange } = useFilterFields({
    options: expandFieldOptions,
    groupByIteratee: (property) =>
      property.multiplicity === MULTIPLICITY['*'].KEY
        ? MULTIPLICITY['*'].KEY
        : MULTIPLICITY['1'].KEY,
  });

  const { handleToggleNavExpansion } = reportViewStateHandlers;

  const handleAlertAndToggleExpansion = (event, status) => {
    handleToggleNavExpansion(schema)(event, status);

    const propertyName = event.currentTarget.name;
    const multiplicity = expandFieldOptionsMap[propertyName].multiplicity;

    if (multiplicity === MULTIPLICITY['*'].KEY) {
      setShowAlert(status);
    }
  };

  return (
    <>
      <Box minWidth={400} maxWidth={680}>
        <TextField
          variant="filled"
          size="small"
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
          label="Search Navigational Property"
          placeholder="example: createdByNav"
          {...commonTextFieldProps}
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            pr: 2,
          }}
        />
        <Box pr={2}>
          <VirtualTree
            groupedFields={groupedFields}
            NodeComponent={ExpandFieldsNode}
            values={expandFieldValues}
            optionsMap={expandFieldOptionsMap}
            toggleNavPropExpansion={handleAlertAndToggleExpansion}
          />
        </Box>
      </Box>
      {showAlert && (
        <Box position="absolute" bottom={20} zIndex={1} pr={2}>
          <Alert
            severity="warning"
            sx={{ width: '100%', mr: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <MdClose fontSize="23px" />
              </IconButton>
            }
          >
            Only <strong>one "N multiplicity"</strong> property can be expanded
          </Alert>
        </Box>
      )}
    </>
  );
}

const ExpandFieldsNode = ({
  data: { isLeaf, name, nestingLevel, property },
  style,
  values,
  optionsMap,
  toggleNavPropExpansion,
  toggleTree,
  treeState,
}) => {
  const multiplicity = getMultiplicity(nestingLevel, name, property);

  return (
    <Stack py={1} mt={1} mr={1} style={style}>
      {isLeaf ? (
        <Stack pl={4.5} pr={1} direction="row" alignItems="center" justifyContent="space-between">
          <Tooltip title={property.name} arrow>
            <Box width="70%">
              <Typography noWrap>{property.name}</Typography>
            </Box>
          </Tooltip>

          <Switch
            size="small"
            disabled={
              (multiplicity === MULTIPLICITY['*'].KEY
                ? checkIfOneNMultiplicityPropertyIsSelected(values, optionsMap)
                : checkIfExpandsExceededLimit(values, optionsMap)) &&
              !values.includes(property.name)
            }
            checked={values.includes(property.name)}
            onChange={toggleNavPropExpansion}
            color="primary"
            name={property.name}
            inputProps={{ 'aria-label': 'checkbox' }}
          />
        </Stack>
      ) : (
        <Stack
          component={Button}
          onClick={toggleTree(multiplicity)}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ textTransform: 'none' }}
          disableRipple
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {treeState[multiplicity] ? (
              <MdKeyboardArrowDown fontSize={20} />
            ) : (
              <MdChevronRight fontSize={20} />
            )}
            <Typography>{MULTIPLICITY[multiplicity].LABEL}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export const checkIfOneNMultiplicityPropertyIsSelected = (values, optionsMap) =>
  values.some((propertyName) => {
    const property = get(optionsMap, propertyName);
    return property.multiplicity === MULTIPLICITY['*'].KEY;
  });

const checkIfExpandsExceededLimit = (values, optionsMap) => {
  const oneMultiplictyExpands = values.filter((propertyName) => {
    const property = get(optionsMap, propertyName);
    return property.multiplicity !== MULTIPLICITY['*'].KEY;
  });

  return oneMultiplictyExpands.length >= 3;
};

const getMultiplicity = (nestingLevel, name, property) => {
  if (nestingLevel === 0) {
    return name;
  } else {
    if (property.multiplicity === MULTIPLICITY['*'].KEY) {
      return MULTIPLICITY['*'].KEY;
    }

    return MULTIPLICITY[1].KEY;
  }
};
