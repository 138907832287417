import { useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import HeaderCell from './HeaderCell';
import { getColumns } from './utils';
import { DropDownEditor, TextEditor } from './Editors';
import { dataflowApiBase, getServiceInstance } from 'service';
import { useTenantState } from 'data/user';
import { FormattedFieldValue } from 'components/DataTable';

/**
 * Gets the columns names of the table.
 */
export default function useColumns({
  visibleFieldsValue,
  visibleFieldsOptionsMap,
  isEditMode = false,
  setMassUpdateDialogState = () => {},
  baseEndpoint = '',
  setMassUpdateDialogInformation,
}) {
  const [picklistValues, setPicklistValues] = useState({});

  const tenant = useTenantState();
  const { connectionId } = useParams();

  const picklistFetcher = useCallback(
    async (picklistId) => {
      try {
        setPicklistValues({});
        const response = await getServiceInstance(tenant?.tenant_id, { isWorker: true }).post(
          `${dataflowApiBase}/connection/${connectionId}/proxy`,
          {
            skipLog: true,
            url: `${baseEndpoint}/Picklist('${picklistId}')?$expand=picklistOptions&$format=JSON`,
          }
        );

        const data = response?.d;

        if (!data) {
          throw new Error('Unable to fetch picklist values');
        }

        if (!data.hasOwnProperty('picklistOptions')) {
          throw new Error('Unable to fetch picklist values');
        }

        const results = data.picklistOptions.results ?? [];

        if (!results.length) {
          return;
        }

        const values = results.map((option) => ({
          id: option.id,
          value: `${option.id} (${option.externalCode ?? ''})`,
        }));

        setPicklistValues((state) => ({ ...state, [picklistId]: values }));
      } catch (error) {
        console.error(error);
      }
    },
    [baseEndpoint, connectionId, tenant]
  );

  return useMemo(() => {
    const columns = getColumns(visibleFieldsValue, visibleFieldsOptionsMap);

    const nested = new Set();
    // Whether a column has a parent. This is applicable in case of nested tables.
    let hasParent = false;

    // The value required by the data grid table is a bit different that what we get from above.
    let transformed = columns.map((it) => {
      // Initially, hasParent should be set to false for every columns unless and until it is proved that
      // it has a parent (orphan until proven)
      hasParent = false;
      const accessor = it.accessor;

      // If the accessor has three dots for example: name.index.key, then it is a part of
      // nested n-multiplicity field.
      const splitAccessor = accessor.split('.');
      const isNested = splitAccessor.length === 3;
      if (isNested) {
        nested.add(splitAccessor[0]);
      }

      hasParent = hasParent || Boolean(it.parent);

      const propertyName = hasParent ? it.name.split('/')[1] : it.name;

      const isEditable = isEditMode && it.upsertable && !hasParent && !it.isKey;
      const isPicklistField = it.upsertable && it.picklist;

      return {
        ...it,
        key: accessor,
        isKey: it.isKey,
        label: propertyName,
        name: it.name,
        resizable: true,
        width: 200,
        // We want all the pinned columns to be frozen and the columns that are primary keys
        // Primary Keys will be frozen only for the root columns and not the expanded columns
        frozen: it.isPinned || (!hasParent && it.isKey),
        headerRenderer: (props) => (
          <HeaderCell
            isEditable={isEditable}
            setMassUpdateDialogState={setMassUpdateDialogState}
            setMassUpdateDialogInformation={setMassUpdateDialogInformation}
            picklistFetcher={picklistFetcher}
            {...props}
            sx={{ opacity: isEditMode ? (isEditable ? 1 : 0.5) : 1 }}
          />
        ),
        type: it.type,
        isNested,
        parent: it.parent,
        visible: it.visible,
        formatter: ({ row, column }) => (
          <Typography sx={{ opacity: isEditMode ? (isEditable ? 1 : 0.5) : 1 }}>
            <FormattedFieldValue value={row[column.key] ?? ''} column={column} />
          </Typography>
        ),
        ...(isEditable && {
          editor: (props) =>
            isPicklistField ? (
              <DropDownEditor
                {...props}
                picklistId={it.picklist}
                options={picklistValues[it.picklist] ?? []}
                picklistFetcher={picklistFetcher}
              />
            ) : (
              <TextEditor {...props} />
            ),
        }),
      };
    });

    const nestedList = Array.from(nested);

    transformed = transformed.map((it) => {
      if (nestedList.length === 0) {
        return it;
      }

      return {
        ...it,
        cellClass: cellClass({
          isNested: it.isNested,
        }),
      };
    });

    return [transformed, nestedList, hasParent, picklistValues, picklistFetcher];
  }, [
    visibleFieldsValue,
    visibleFieldsOptionsMap,
    isEditMode,
    setMassUpdateDialogState,
    setMassUpdateDialogInformation,
    picklistValues,
    picklistFetcher,
  ]);
}

function cellClass({ isNested }) {
  return (row) => {
    // we don't want to add a top-border to the first record in the list
    const isFirst = row.lf_dataflow_merge_is_first && row.index !== 0;
    if (isFirst) {
      return `cell merged-first-cell ${isNested ? 'cell' : 'cell merged-cell'}`;
    }

    return !isNested ? 'cell merged-cell' : 'cell';
  };
}
