/**
 * @file
 *
 * this file contains the code to parse the query Url of an OData endpoint
 */
import odataQueryStringParser from '@integrtr/odata-query-string-parser';
import { checkIsVariableValid } from 'hooks/useDateTimePicker';
import { flatten, get, initial } from 'lodash-es';
import { nanoid } from 'nanoid';
import queryStringParser from 'query-string';
import SuperExpressive from 'super-expressive';

import { SYSTEMS } from '../constants';
import { parseEdmTime } from '../date';
import {
  computeExpandOptions,
  computeColumnSelectOptions,
  computeOrderByOptions,
  computeWhereOptions,
  OPERATORS,
  validatePaginationParam,
  PAGINATION,
} from '../state/queryBuilder';
import { cast } from '../utils';
import {
  filterOptions,
  intTypes,
  floatingPointTypes,
  typeBasedFilterOptions,
  canQueryDataType,
  relativeDateTimeOptions,
} from './queryBuilder';
import {
  ENUMS,
  flattenEntityType,
  getEntityTypeFromSetName,
  getTargetEntityTypeFromExpandOption,
  ODATA_DATA_TYPES,
} from './utils';

/**
 * Errors that can occur during the parsing and relevant messages
 */
const QUERY_URL_PARSE_ERROR_TYPES = {
  INVALID_URL: 'The URL is invalid',
  BASE_ENDPOINT_MISMATCH: 'The base endpoint is different',
  MISSING_ENTITY: 'No entity found in the url',
  INVALID_ENTITY: 'The entity is invalid',
  INVALID_QUERY: 'It is not a valid standalone query',
  PROPERTY_FILTERABLE_FALSE: 'An used property is marked as not-filterable in metadata',
  PROPERTY_DATATYPE_UNSUPPORTED: 'An used property is of unsupported datatype',
};

/**
 * Enums of valid types and function calls in the parsed AST
 */
const PARSED_ENUM = {
  TYPE: {
    and: 'and',
    or: 'or',
    eq: 'eq',
    ne: 'ne',
    gt: 'gt',
    ge: 'ge',
    lt: 'lt',
    le: 'le',
    property: 'property',
    literal: 'literal',
    functioncall: 'functioncall',
  },
  FUNCTION: {
    tolower: 'tolower',
    startswith: 'startswith',
    endswith: 'endswith',
    substringof: 'substringof',
    length: 'length',
    round: 'round',
    floor: 'floor',
    ceiling: 'ceiling',
    day: 'day',
    month: 'month',
    year: 'year',
    hour: 'hour',
    minute: 'minute',
    second: 'second',
  },
};

/**
 * @function
 *
 * @param {any} value       can be a primitve value or an array of 'null' string
 *
 * @return {any}            return the primitve value or null
 */
const flattenParamValue = (value) => (Array.isArray(value) ? null : value);

/**
 * This function sets the property, value and operator as a newRule in the where clause
 *
 * @param {object} property
 * @param {string} paramValue
 * @param {string} operator
 * @param {object} newRule
 */
const setRule = (property, paramValue, operator, newRule) => {
  newRule.property = property;

  if (property.type === ODATA_DATA_TYPES['Edm.Boolean']) {
    if (paramValue === true) {
      newRule.filter = filterOptions.isTrue;
    }

    if (paramValue === false) {
      newRule.filter = filterOptions.isFalse;
    }
  }

  if (intTypes.includes(property.type || floatingPointTypes.includes(property.type))) {
    const datatype = intTypes.includes(property.type) ? 'int' : 'float';
    newRule.param = cast[datatype](paramValue);

    switch (operator) {
      case PARSED_ENUM.TYPE['eq']: {
        newRule.filter = filterOptions['eq'];
        break;
      }
      case PARSED_ENUM.TYPE['ne']: {
        newRule.filter = filterOptions['ne'];
        break;
      }
      case PARSED_ENUM.TYPE['lt']: {
        newRule.filter = filterOptions['lt'];
        break;
      }
      case PARSED_ENUM.TYPE['le']: {
        newRule.filter = filterOptions['le'];
        break;
      }
      case PARSED_ENUM.TYPE['gt']: {
        newRule.filter = filterOptions['gt'];
        break;
      }
      case PARSED_ENUM.TYPE['ge']: {
        newRule.filter = filterOptions['ge'];
        break;
      }
      default: {
        newRule.filter = null;
      }
    }
  }

  if (property.type === ODATA_DATA_TYPES['Edm.String']) {
    newRule.param = paramValue;

    if (operator === PARSED_ENUM.TYPE['eq']) {
      newRule.filter = filterOptions['eq'];
    }

    if (operator === PARSED_ENUM.TYPE['ne']) {
      newRule.filter = filterOptions['ne'];
    }
  }

  if (property.type === ODATA_DATA_TYPES['Edm.Guid']) {
    newRule.param = paramValue;

    if (operator === PARSED_ENUM.TYPE['eq']) {
      newRule.filter = filterOptions['guidEq'];
    }

    if (operator === PARSED_ENUM.TYPE['ne']) {
      newRule.filter = filterOptions['guidNe'];
    }
  }

  if (
    property.type === ODATA_DATA_TYPES['Edm.DateTime'] ||
    property.type === ODATA_DATA_TYPES['Edm.DateTimeOffset']
  ) {
    if (checkIsVariableValid(paramValue)) {
      newRule.param = relativeDateTimeOptions[paramValue];
    } else {
      newRule.param = cast.date(
        paramValue,
        property.type === ODATA_DATA_TYPES['Edm.DateTimeOffset']
      );
    }

    switch (operator) {
      case PARSED_ENUM.TYPE['eq']: {
        newRule.filter = filterOptions['datetimeEq'];
        break;
      }
      case PARSED_ENUM.TYPE['ne']: {
        newRule.filter = filterOptions['datetimeNe'];
        break;
      }

      case PARSED_ENUM.TYPE['le']: {
        newRule.filter = filterOptions['datetimeEqOrBefore'];
        break;
      }

      case PARSED_ENUM.TYPE['lt']: {
        newRule.filter = filterOptions['datetimeBefore'];
        break;
      }

      case PARSED_ENUM.TYPE['gt']: {
        newRule.filter = filterOptions['datetimeAfter'];
        break;
      }
      case PARSED_ENUM.TYPE['ge']: {
        newRule.filter = filterOptions['datetimeEqOrAfter'];
        break;
      }
      default: {
        newRule.filter = null;
      }
    }
  }

  if (property.type === ODATA_DATA_TYPES['Edm.Time']) {
    newRule.param = cast.date(paramValue);

    switch (operator) {
      case PARSED_ENUM.TYPE['eq']: {
        newRule.filter = filterOptions['timeEq'];
        break;
      }
      case PARSED_ENUM.TYPE['ne']: {
        newRule.filter = filterOptions['timeNe'];
        break;
      }
      case PARSED_ENUM.TYPE['le']: {
        newRule.filter = filterOptions['timeEqOrBefore'];
        break;
      }
      case PARSED_ENUM.TYPE['lt']: {
        newRule.filter = filterOptions['timeBefore'];
        break;
      }
      case PARSED_ENUM.TYPE['gt']: {
        newRule.filter = filterOptions['timeAfter'];
        break;
      }
      case PARSED_ENUM.TYPE['ge']: {
        newRule.filter = filterOptions['timeEqOrAfter'];
        break;
      }
      default: {
        newRule.filter = null;
      }
    }
  }
};

/**
 * @function
 *
 * a curried function that returns recursive AST walker function
 *
 * @param {object} where          where slice of the query builder state
 * @param {string} mutatePath     stringified path where the rules need to appended to in the where slice
 * @param {boolean} isSfSystem    whether it's a SF EC system
 *
 * @returns {(subtree:object) => void} the returned function accepts a subtree of the AST output of the parser
 */
const astWalker = (where, mutatePath, isSfSystem) => (subtree) => {
  if (subtree.type === PARSED_ENUM.TYPE.and || subtree.type === PARSED_ENUM.TYPE.or) {
    const newGroup = {
      id: nanoid(),
      operator: subtree.type,
      rules: [],
    };
    const rules = get(where, mutatePath);
    const rulesLength = rules.push(newGroup);

    const traverse = astWalker(where, `${mutatePath}[${rulesLength - 1}].rules`);

    traverse(subtree.left);
    traverse(subtree.right);
  } else {
    let paramValue = flattenParamValue(subtree?.right?.value);
    const operator = subtree?.type;
    const newRule = { id: nanoid() };

    if (
      subtree.left.type === PARSED_ENUM.TYPE.property &&
      subtree.right.type === PARSED_ENUM.TYPE.literal
    ) {
      const property = where.options.find((opt) => opt.name === subtree.left.name);

      if (property) {
        setRule(property, paramValue, operator, newRule);
      }
    } else if (
      subtree.left.type === PARSED_ENUM.TYPE.literal &&
      subtree.right.type === PARSED_ENUM.TYPE.property
    ) {
      const property = where.options.find((opt) => opt.name === subtree.right.name);
      paramValue = flattenParamValue(subtree?.left?.value);

      if (property) {
        setRule(property, paramValue, operator, newRule);
      }
    } else if (
      subtree.left.type === PARSED_ENUM.TYPE.functioncall &&
      subtree.right.type === PARSED_ENUM.TYPE.literal
    ) {
      const func = subtree.left.func;
      const propertyName = subtree.left?.args?.find((arg) => arg.type === 'property')?.name;
      const property = where.options.find((opt) => opt.name === propertyName);

      const argParam = subtree.left?.args?.find((arg) => arg.type === 'literal')?.value ?? null;

      if (property) {
        newRule.property = property;

        if (operator === PARSED_ENUM.TYPE.eq || operator === PARSED_ENUM.TYPE.ne) {
          // for string funcitons like startswith, endswith and substringof
          // these only have `eq` operator and `boolean` function arg param value
          if (operator === PARSED_ENUM.TYPE.eq && typeof paramValue === 'boolean') {
            newRule.param = argParam;
            if (func === PARSED_ENUM.FUNCTION.startswith) {
              newRule.filter =
                paramValue === true ? filterOptions.startsWith : filterOptions.notStartsWith;
            } else if (func === PARSED_ENUM.FUNCTION.endswith) {
              newRule.filter =
                paramValue === true ? filterOptions.endsWith : filterOptions.notEndsWith;
            } else if (func === PARSED_ENUM.FUNCTION.substringof) {
              newRule.filter =
                paramValue === true ? filterOptions.contains : filterOptions.notContains;
            }
          }
          // for string functions like tolower
          // these always have `eq` or `ne` operators
          else if (func === PARSED_ENUM.FUNCTION.tolower) {
            newRule.filter =
              operator === PARSED_ENUM.TYPE.eq
                ? filterOptions.caseInsensitiveEq
                : filterOptions.caseInsensitiveNe;
            newRule.param = paramValue;
          }
          // for decimal functions like round, floor and ceiling
          // these only have `eq` operator
          else if (operator === PARSED_ENUM.TYPE.eq) {
            newRule.param = cast.int(paramValue);

            if (func === PARSED_ENUM.FUNCTION.round) {
              newRule.filter = filterOptions.roundEq;
            } else if (func === PARSED_ENUM.FUNCTION.ceiling) {
              newRule.filter = filterOptions.ceilingEq;
            } else if (func === PARSED_ENUM.FUNCTION.floor) {
              newRule.filter = filterOptions.floorEq;
            }
          }
        }

        // for string functions like length, day, month, year, hour, minute, second
        const numericCompareFns = [
          PARSED_ENUM.FUNCTION.length,
          PARSED_ENUM.FUNCTION.day,
          PARSED_ENUM.FUNCTION.month,
          PARSED_ENUM.FUNCTION.year,
          PARSED_ENUM.FUNCTION.hour,
          PARSED_ENUM.FUNCTION.minute,
          PARSED_ENUM.FUNCTION.second,
        ];
        if (numericCompareFns.includes(func)) {
          newRule.param = cast.int(paramValue);

          if (operator === PARSED_ENUM.TYPE.eq) {
            newRule.filter = filterOptions[`${func}Eq`];
          } else if (operator === PARSED_ENUM.TYPE.ne) {
            newRule.filter = filterOptions[`${func}Ne`];
          } else if (operator === PARSED_ENUM.TYPE.lt) {
            newRule.filter = filterOptions[`${func}Lt`];
          } else if (operator === PARSED_ENUM.TYPE.le) {
            newRule.filter = filterOptions[`${func}Le`];
          } else if (operator === PARSED_ENUM.TYPE.gt) {
            newRule.filter = filterOptions[`${func}Gt`];
          } else if (operator === PARSED_ENUM.TYPE.ge) {
            newRule.filter = filterOptions[`${func}Ge`];
          }
        }
      }
    }

    if (newRule.filter && newRule.property) {
      // add the new rule to the where clause if filter and property is present
      const validFilters = typeBasedFilterOptions[newRule.property.type];

      if (validFilters.some((filter) => filter.key === newRule.filter.key)) {
        if (!newRule.filter.disabled?.({ isSfSystem })) {
          const rules = get(where, mutatePath);
          rules.push(newRule);
        }
      }
    }
  }
};

// first, we check if the query url is key ref based
/**
   * Formatted source of the RegEx
   * 
   * SuperExpressive()
      .singleLine
      .startOfInput
      .string(baseUrl)
      .char('/')
      .namedCapture('entitySetName')
          .atLeast(1)
          .word
      .end()
      .char('(')
      .namedCapture('keyRefArgs')
          .atLeast(1)
          .nonWhitespaceChar
      .end()
      .char(')')
      .optional
          .group
              .char('/')
              .namedCapture('expand')
                  .atLeast(1)
                  .word
              .end()
          .end()
      .toRegex();
   * 
   * Output regex - /^https:\/\/services\.odata\.org\/V2\/OData\/OData\.svc\/(?<entitySetName>\w{1,})\((?<keyRefArgs>\S{1,})\)(?:\/(?<expand>\w{1,}))?/
   */
export const getKeyRefBasedQueryUrlRegex = (parsedUrl, baseEndpoint) => {
  const keyRefBasedQueryUrlRegex = SuperExpressive()
    .singleLine.startOfInput.string(baseEndpoint)
    .char('/')
    .namedCapture('entitySetName')
    .atLeast(1)
    .word.end()
    .char('(')
    .namedCapture('keyRefArgs')
    .atLeast(1)
    .nonWhitespaceChar.end()
    .char(')')
    .optional.group.char('/')
    .namedCapture('expand')
    .atLeast(1)
    .word.end()
    .end()
    .toRegex();

  return keyRefBasedQueryUrlRegex.exec(parsedUrl);
};

/**
 * @function
 *
 * This function accepts necessary params to validate and parse a query url
 * and return the resultant query builder state needed to populate the query builder component
 *
 * @param {object} param
 * @param {string} param.queryUrl         query url that needs to be populated in the query builder
 * @param {string} param.systemType       type of the OData system
 * @param {string} param.baseEndpoint     base endpoint to check if the url is for the same system
 * @param {object} param.schema           metadata schema of the endpoint
 */
export function parseQueryUrlAndPopulateQueryBuilder({
  queryUrl,
  baseEndpoint,
  schema,
  systemType,
}) {
  let parsedUrl;
  try {
    parsedUrl = new URL(queryUrl?.trim());
  } catch (err) {
    throw new Error(QUERY_URL_PARSE_ERROR_TYPES.INVALID_URL);
  }

  const isSfSystem = systemType === SYSTEMS.SF_EC.KEY;

  const regexParsedResult = getKeyRefBasedQueryUrlRegex(parsedUrl.href, baseEndpoint);

  // if entity set name and key ref args are captured then only we compute further assuming it is a key ref based url
  // otherwise we continue assuming it is paginated url as in using $top
  if (regexParsedResult?.groups?.entitySetName && regexParsedResult?.groups?.keyRefArgs) {
    const selectedEntity = getEntityTypeFromSetName(regexParsedResult.groups.entitySetName, schema);

    if (!selectedEntity) {
      throw new Error(QUERY_URL_PARSE_ERROR_TYPES.INVALID_ENTITY);
    }

    const entity = flattenEntityType(selectedEntity, schema.complexTypesMap);

    const queryBuilderState = {
      queryKey: nanoid(),
      entity,
      top: 1,
      skip: 0,
      effectiveRange: {
        asOfDate: null,
        fromDate: null,
        toDate: null,
      },
      where: {
        options: computeWhereOptions(entity, schema, isSfSystem),
        value: {
          id: nanoid(),
          operator: OPERATORS.AND,
          rules: [],
        },
      },
      expand: {
        options: computeExpandOptions(entity, schema),
        value: [],
      },
      columnSelect: {
        options: computeColumnSelectOptions(entity, schema, []),
        value: [],
        flag: ENUMS.COLUMN_SELECT_FLAG.INCLUDE,
      },
      orderBy: {
        options: computeOrderByOptions(entity),
        value: [],
        flags: {},
      },
    };

    const keyValuePairs = regexParsedResult.groups.keyRefArgs.split(',');

    const addWhereRules = (keyProperty, paramValue) => {
      if (!keyProperty) {
        throw new Error(QUERY_URL_PARSE_ERROR_TYPES.PROPERTY_FILTERABLE_FALSE);
      }
      if (!canQueryDataType(keyProperty.type)) {
        throw new Error(QUERY_URL_PARSE_ERROR_TYPES.PROPERTY_DATATYPE_UNSUPPORTED);
      }

      const rule = {
        id: nanoid(),
        property: keyProperty,
      };

      rule.filter = filterOptions.eq;
      if (intTypes.includes(keyProperty.type)) {
        rule.param = cast.int(paramValue);
      } else if (floatingPointTypes.includes(keyProperty.type)) {
        rule.param = cast.float(paramValue);
      } else if (keyProperty.type === ODATA_DATA_TYPES['Edm.DateTime']) {
        const datetimePrefix = `datetime'`;
        if (
          typeof paramValue === 'string' &&
          paramValue.startsWith(datetimePrefix) &&
          paramValue.endsWith(`'`)
        ) {
          if (checkIsVariableValid(paramValue)) {
            rule.param = relativeDateTimeOptions[paramValue];
          } else {
            rule.param = cast.date(paramValue.slice(datetimePrefix.length, -1));
          }
        } else {
          rule.param = null;
        }
        rule.filter = filterOptions.datetimeEq;
      } else if (keyProperty.type === ODATA_DATA_TYPES['Edm.DateTimeOffset']) {
        const datetimePrefix = `datetime'`;
        const datetimeoffsetPrefix = `datetimeoffset'`;
        if (
          typeof paramValue === 'string' &&
          (paramValue.startsWith(datetimePrefix) || paramValue.startsWith(datetimePrefix)) &&
          paramValue.endsWith(`'`)
        ) {
          if (checkIsVariableValid(paramValue)) {
            rule.param = relativeDateTimeOptions[paramValue];
          } else {
            rule.param = cast.date(
              paramValue.slice(
                paramValue.startsWith(datetimePrefix)
                  ? datetimePrefix.length
                  : datetimeoffsetPrefix.length,
                -1
              ),
              paramValue.startsWith(datetimeoffsetPrefix)
            );
          }
        } else {
          rule.param = null;
        }
        rule.filter = filterOptions.datetimeEq;
      } else if (keyProperty.type === ODATA_DATA_TYPES['Edm.Time']) {
        const timePrefix = `time'`;

        if (
          typeof paramValue === 'string' &&
          paramValue.startsWith(timePrefix) &&
          paramValue.endsWith(`'`)
        ) {
          rule.param = parseEdmTime(paramValue.slice(timePrefix.length, -1));
        } else {
          rule.param = null;
        }
      } else if (keyProperty.type === ODATA_DATA_TYPES['Edm.Guid']) {
        const prefix = `guid'`;

        if (
          typeof paramValue === 'string' &&
          paramValue.startsWith(prefix) &&
          paramValue.endsWith(`'`)
        ) {
          rule.param = paramValue.slice(prefix, -1);
        } else {
          rule.param = null;
        }
      } else if (keyProperty.type === ODATA_DATA_TYPES['Edm.String']) {
        // the regex parser parsed string with quotes
        if (
          typeof paramValue === 'string' &&
          ((paramValue.startsWith(`'`) && paramValue.endsWith(`'`)) ||
            (paramValue.startsWith(`"`) && paramValue.endsWith(`"`)))
        ) {
          // trim the quotes
          rule.param = paramValue.slice(1, -1) || null;
        } else {
          rule.param = null;
        }
      }

      queryBuilderState.where.value.rules.push(rule);
    };

    if (!keyValuePairs.length) {
      throw new Error(QUERY_URL_PARSE_ERROR_TYPES.INVALID_QUERY);
    } else if (keyValuePairs.length === 1) {
      const paramValue = keyValuePairs[0];
      const keyProperty = queryBuilderState.where.options.find((prop) => prop.isKey);

      addWhereRules(keyProperty, paramValue);
    } else {
      for (let keyValue of keyValuePairs) {
        const [key, paramValue] = keyValue.split('=');

        if (!key || !paramValue) {
          throw new Error(QUERY_URL_PARSE_ERROR_TYPES.INVALID_QUERY);
        }

        const keyProperty = queryBuilderState.where.options.find(
          (prop) => prop.isKey && prop.name === key
        );

        addWhereRules(keyProperty, paramValue);
      }
    }

    // add expand if we have any
    if (regexParsedResult?.groups?.expand) {
      const expandedValue = queryBuilderState.expand.options.find(
        (opt) => opt.name === regexParsedResult.groups.expand
      );

      if (expandedValue) {
        queryBuilderState.expand.value.push(expandedValue);
      }
    }

    return queryBuilderState;
  }

  //  this is if the query url is not key ref based
  const baseUrl = initial(`${parsedUrl.origin}${parsedUrl.pathname}`.split('/')).join('/');

  if (baseEndpoint !== baseUrl) {
    throw new Error(QUERY_URL_PARSE_ERROR_TYPES.BASE_ENDPOINT_MISMATCH);
  }

  const entitySetName = parsedUrl.pathname.split('/').pop();

  if (!entitySetName) {
    throw new Error(QUERY_URL_PARSE_ERROR_TYPES.MISSING_ENTITY);
  }

  const selectedEntity = getEntityTypeFromSetName(entitySetName, schema);

  if (!selectedEntity) {
    throw new Error(QUERY_URL_PARSE_ERROR_TYPES.INVALID_ENTITY);
  }

  // query builder state - entity
  const entity = flattenEntityType(selectedEntity, schema.complexTypesMap);

  // query builder state - where
  const where = {
    options: computeWhereOptions(entity, schema, isSfSystem),
    value: {
      id: nanoid(),
      operator: OPERATORS.AND,
      rules: [],
    },
  };

  const traverse = astWalker(where, 'value.rules', isSfSystem);

  const { query = {} } = queryStringParser.parseUrl(queryUrl);

  if (query.$filter) {
    try {
      const filterQueryString = `$filter=${query.$filter}`;

      const ast = odataQueryStringParser.parse(filterQueryString);

      if (ast.$filter) {
        if (ast.$filter.type === PARSED_ENUM.TYPE.and) {
          where.value.operator = OPERATORS.AND;

          traverse(ast.$filter.left);
          traverse(ast.$filter.right);
        } else if (ast.$filter.type === PARSED_ENUM.TYPE.or) {
          where.value.operator = OPERATORS.OR;

          traverse(ast.$filter.left);
          traverse(ast.$filter.right);
        } else {
          traverse(ast.$filter);
        }
      }
    } catch (err) {
      throw new Error(QUERY_URL_PARSE_ERROR_TYPES.INVALID_QUERY);
    }
  }

  // query builder state - pagination
  const top = validatePaginationParam.top(query.$top) ?? PAGINATION.TOP.DEFAULT;
  const skip = validatePaginationParam.skip(query.$skip) ?? PAGINATION.SKIP.DEFAULT;

  // query builder state - effectiveRange
  let effectiveRange = {
    asOfDate: null,
    fromDate: null,
    toDate: null,
  };
  if (isSfSystem) {
    if (checkIsVariableValid(query.asOfDate)) {
      effectiveRange.asOfDate = relativeDateTimeOptions[query.asOfDate];
    } else {
      effectiveRange.asOfDate = cast.date(query.asOfDate);
    }

    if (!effectiveRange.asOfDate) {
      if (checkIsVariableValid(query.fromDate)) {
        effectiveRange.fromDate = relativeDateTimeOptions[query.fromDate];
      } else {
        effectiveRange.fromDate = cast.date(query.fromDate);
      }

      if (checkIsVariableValid(query.toDate)) {
        effectiveRange.toDate = relativeDateTimeOptions[query.toDate];
      } else {
        effectiveRange.toDate = cast.date(query.toDate);
      }
    }
  }

  // query builder state - expand
  const expand = {
    options: computeExpandOptions(entity, schema),
    value: [],
  };
  const expandedValues = query.$expand?.split(',') ?? [];
  flatten(
    expandedValues.reduce((acc, value) => {
      const chunks = value.split('/');
      const level = chunks.length - 1;

      if (!acc[level]) {
        acc[level] = [];
      }

      acc[level].push(value);

      return acc;
    }, [])
  ).forEach((value) => {
    const option = expand.options.find((opt) => opt.name === value);

    if (option) {
      const alreadySelected = expand.value.some((opt) => opt.name === option.name);

      if (!alreadySelected) {
        expand.value.push(option);

        const entityType = getTargetEntityTypeFromExpandOption(option, schema);
        const newOptions = computeExpandOptions(entityType, schema, option.name);

        expand.options = expand.options.concat(newOptions);
      }
    }
  });

  // query builder state - columnSelect
  const columnSelect = {
    options: computeColumnSelectOptions(entity, schema, expand.value),
    value: [],
    flag: ENUMS.COLUMN_SELECT_FLAG.INCLUDE,
  };
  const selectedValues = query.$select?.split(',') ?? [];
  columnSelect.value = columnSelect.options.filter((opt) => selectedValues.includes(opt.name));

  // query builder state - orderBy
  const orderBy = {
    options: computeOrderByOptions(entity),
    value: [],
    flags: {},
  };
  // !! the map iteratee below is impure. Caution: bear that in mind when refactoring.
  const orderedValues =
    query.$orderby?.split(',').map((prop) => {
      const [propName, flag] = prop.split(' ');

      orderBy.flags[propName] =
        flag === ENUMS.ORDER_BY_FLAG.DESC ? ENUMS.ORDER_BY_FLAG.DESC : ENUMS.ORDER_BY_FLAG.ASC;

      return propName;
    }) ?? [];
  orderBy.value = orderBy.options.filter((opt) => orderedValues.includes(opt.name));

  const queryBuilderState = {
    queryKey: nanoid(),
    entity,
    top,
    skip,
    effectiveRange,
    where,
    expand,
    columnSelect,
    orderBy,
  };

  return queryBuilderState;
}
