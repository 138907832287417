import React, { useCallback, useMemo, useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
  Box,
  Stack,
  Button,
  CircularProgress,
  DialogActions,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';
import { FiMoreVertical } from 'react-icons/fi';
import { MdContentCopy, MdDelete, MdEdit, MdShare } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { constructURL, getBillingExpiryStatus } from 'utils';
import { useBillingUsage } from 'data/billingUsage';
import { getBookmarkErrorText, useDeleteBookmarkDialog } from 'components/BookmarkPanel';
import { ErrorImage } from 'components/Illustrations/Error';
import { NoBookmarksImage } from 'components/Illustrations/NoBookmarks';
import { hydrateReportViewState } from 'state/reportView';
import { useEditBookmarkMutaton } from 'data/queriesAndBookmarks';
import { validationSchema } from 'components/CreateBookmarkDialog';
import { useTenantState } from 'data/user';
import { commonTextFieldProps } from 'mui5Theme';
import { useEditBookmark } from 'components/EditBookmarkDialog';
import { useCopy } from 'hooks/useCopy';
import { useShareableUrl } from 'state/queryBuilder';
import { toast } from 'react-hot-toast';
import { initialQueryDataState } from 'components/ReportView';

export default function BookmarkPanel({
  data,
  isLoading,
  error,
  schema,
  baseEndpoint,
  systemType,
  setQueryData,
}) {
  const { data: billingUsage } = useBillingUsage();
  const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);

  const { isEditDialogOpen, openEditDialog, closeEditDialog, selectedBookmark } = useEditBookmark();

  if (isLoading && !hasPlanExpired) {
    return (
      <Stack spacing={1} p={1}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="100%"
              height={80}
              sx={{ borderRadius: 1 }}
            />
          ))}
      </Stack>
    );
  }

  // if error exists then display error, if error doesn't exists then check if the plan has expired. If the plan has expired,
  // then show the plan expiry message.
  if (error || hasPlanExpired) {
    return (
      <Stack height="100%" alignItems="center" mt={10}>
        <ErrorImage width="180px" height="200px" />
        <Typography color="error">{getBookmarkErrorText(error, hasPlanExpired)}</Typography>
      </Stack>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Stack height="100%" alignItems="center" mt={10}>
        <NoBookmarksImage width="180px" height="200px" />
        <Typography color="textSecondary">No Bookmarks Available</Typography>
      </Stack>
    );
  }

  return (
    <>
      {data.map((bookmark) => (
        <Bookmark
          baseEndpoint={baseEndpoint}
          systemType={systemType}
          schema={schema}
          key={bookmark.query_bookmark_id}
          bookmark={bookmark}
          openEditDialog={openEditDialog}
          setQueryData={setQueryData}
        />
      ))}
      {selectedBookmark && (
        <EditBookmarkDialog
          selectedBookmark={selectedBookmark}
          isOpen={isEditDialogOpen}
          handleClose={closeEditDialog}
        />
      )}
    </>
  );
}

export function Bookmark({
  bookmark,
  baseEndpoint,
  systemType,
  schema,
  openEditDialog,
  setQueryData,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const deleteBookmark = useDeleteBookmarkDialog();

  const copyToClipboard = useCopy();
  const { getShareableUrl } = useShareableUrl();

  const bookmarkedEntity = useMemo(() => {
    const urlObj = constructURL(bookmark.query_string);
    if (!urlObj) {
      return '';
    }
    const entityRegex = /\/odata\/v2\/(.*)/;
    const pathname = urlObj.pathname;
    const match = pathname.match(entityRegex);

    return match?.[1];
  }, [bookmark]);

  const handleCopyShareableURL = useCallback(() => {
    try {
      const url = new URL(bookmark.query_string);
      url.searchParams.delete('$format');
      url.searchParams.delete('$inlinecount');
      url.searchParams.delete('$orderby');

      const shareableURL = getShareableUrl(url.toString());
      const shareableUrlObj = new URL(shareableURL);

      const odataQuery = shareableUrlObj.searchParams.get('odataQuery');
      const decodedODataQuery = decodeURIComponent(odataQuery);
      const strippedODataQuery = decodedODataQuery.replace(`${baseEndpoint}/`, '');

      shareableUrlObj.searchParams.set('odataQuery', strippedODataQuery);

      copyToClipboard(shareableUrlObj.href);
    } catch (error) {
      toast.error('Copy to clipboard failed!', {
        id: 'copy-shareable-query-error',
        duration: 3000,
      });
    } finally {
      handleMenuClose();
    }
  }, [baseEndpoint, bookmark.query_string, copyToClipboard, getShareableUrl, handleMenuClose]);

  return (
    <Stack borderBottom="1px solid lightgray" spacing={1} p={1.5}>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Tooltip title={bookmark.name}>
            <Typography
              onClick={() => {
                hydrateReportViewState(bookmark.query_string, baseEndpoint, schema, systemType);
                setQueryData(initialQueryDataState);
              }}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              variant="body1"
              fontWeight={500}
              noWrap
            >
              {bookmark.name}
            </Typography>
          </Tooltip>
          <IconButton size="small" disableRipple onClick={handleMenuOpen}>
            <FiMoreVertical />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                openEditDialog(bookmark);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <MdEdit />
              </ListItemIcon>
              Edit Bookmark
            </MenuItem>
            <MenuItem
              onClick={() => {
                copyToClipboard(bookmark.query_string);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <MdContentCopy fontSize="18px" />
              </ListItemIcon>
              Copy Query URL
            </MenuItem>
            <MenuItem onClick={handleCopyShareableURL}>
              <ListItemIcon>
                <MdShare />
              </ListItemIcon>
              Copy Shareable URL
            </MenuItem>
            <MenuItem
              sx={{ color: 'red' }}
              onClick={() => {
                deleteBookmark(bookmark.query_bookmark_id);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <MdDelete color="red" />
              </ListItemIcon>
              Delete Bookmark
            </MenuItem>
          </Menu>
        </Stack>
        <Tooltip title={bookmark?.description ?? ''} color="textSecondary">
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {bookmark?.description ?? ''}
          </Typography>
        </Tooltip>
        <Box
          borderRadius={1}
          p={1}
          mt={1}
          mb={0.5}
          sx={{
            display: 'flex',
            whiteSpace: 'nowrap',
            '& > *': {
              marginRight: '1rem', // Adjust this value to control the spacing between items
            },
            backgroundColor: '#ededed',
            overflowX: 'auto',
            overflowY: 'hidden',
            '&::-webkit-scrollbar': {
              width: 5,
              height: 5,
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: 20,
              border: '3px solid #929394',
            },
          }}
        >
          <Typography variant="body2" fontFamily="monospace">
            {decodeURIComponent(bookmark.query_string)}
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="body2">Entity:</Typography>
          <Typography variant="body2" fontWeight="500">
            {bookmarkedEntity ?? 'N/A'}
          </Typography>
        </Stack>
        <Typography color="textSecondary" variant="body2">
          + customizations
        </Typography>
      </Stack>
    </Stack>
  );
}

export function EditBookmarkDialog({ isOpen, handleClose, selectedBookmark }) {
  const { editBookmarkMutation } = useEditBookmarkMutaton();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      name: selectedBookmark.name,
      description: selectedBookmark.description,
    },
  });

  const tenant = useTenantState();

  const onSubmit = useCallback(
    async (data) => {
      try {
        await editBookmarkMutation.mutateAsync({
          formData: data,
          tenant,
          selectedBookmark,
        });

        handleClose();
      } finally {
      }
    },
    [selectedBookmark, handleClose, tenant, editBookmarkMutation]
  );

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Bookmark</DialogTitle>
        <DialogContent>
          <TextField
            label="Bookmark Title"
            placeholder="Favourite Query"
            name="name"
            helperText={errors.name ? errors.name.message : ' '}
            error={Boolean(errors.name)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={editBookmarkMutation.isLoading}
          />
          <TextField
            label="Bookmark Description"
            placeholder="This is my favorite query"
            name="description"
            helperText={errors.description ? errors.description.message : ' '}
            error={Boolean(errors.description)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={editBookmarkMutation.isLoading}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="small"
            disabled={editBookmarkMutation.isLoading || !isValid}
            type="submit"
            startIcon={editBookmarkMutation.isLoading ? <CircularProgress size={15} /> : null}
          >
            {editBookmarkMutation.isLoading ? 'Updating' : 'Update'}
          </Button>
          <Button
            disableElevation
            variant="outlined"
            size="small"
            disabled={editBookmarkMutation.isLoading}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
