import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React, { useCallback } from 'react';

export default function DialogWrapper({
  isOpen,
  closeDialog,
  title,
  children,
  secondaryBtnText = 'Close',
  primaryBtnText = 'Continue',
  tertiaryBtnText = '',
  otherBtnText = '',
  secondaryBtnAction,
  primaryBtnAction,
  tertiaryBtnAction,
  otherBtnAction,
  disableBackdropClick = false,
  secondaryBtnProps = {},
  primaryBtnProps = {},
  tertiaryBtnProps = {},
  otherBtnProps = {},
  progress = null,
  size = 'sm',
}) {
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === 'backdropClick' && disableBackdropClick) {
        return;
      }

      if (closeDialog && closeDialog instanceof Function) {
        closeDialog();
      }
    },
    [closeDialog, disableBackdropClick]
  );

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={size} fullWidth sx={{ zIndex: 10001 }}>
      <DialogTitle>
        <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
          {title} {Number.isInteger(progress) ? `(${progress}%)` : ''}
          {tertiaryBtnAction && (
            <Button
              size="small"
              variant="outlined"
              onClick={tertiaryBtnAction}
              {...tertiaryBtnProps}
            >
              {tertiaryBtnText}
            </Button>
          )}
        </Stack>
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ width: '100%' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={otherBtnAction ? 'space-between' : 'flex-end'}
          width="100%"
        >
          {otherBtnAction && (
            <Button
              size="small"
              variant="outlined"
              onClick={otherBtnAction}
              disableElevation
              {...otherBtnProps}
            >
              {otherBtnText}
            </Button>
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            {secondaryBtnAction && (
              <Button
                size="small"
                variant="outlined"
                onClick={secondaryBtnAction}
                {...secondaryBtnProps}
              >
                {secondaryBtnText}
              </Button>
            )}
            {primaryBtnAction && (
              <Button
                size="small"
                variant="contained"
                onClick={primaryBtnAction}
                disableElevation
                {...primaryBtnProps}
              >
                {primaryBtnText}
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
