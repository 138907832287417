import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  ListItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Box,
  Badge,
  Tooltip,
} from '@mui/material';
import React, { useRef } from 'react';
import { MdArrowDownward, MdArrowUpward, MdDelete, MdVpnKey } from 'react-icons/md';

import { useDisclosure } from '../../hooks/useDisclosure';
import { ENUMS } from '../../odata/utils';
import { reportViewStateHandlers, useReportViewState } from '../../state/reportView';
import { commonTextFieldProps } from '../../theme';
import { AutocompleteVirtualizedListbox } from '../AutocompleteVirtualizedListbox';
import {
  autoCompleteFilterOptions,
  AutocompleteOption,
} from '../FormComponents/AutocompleteOption';
import PopoverMenu from './PopoverMenu';

const entityValueSelector = (state) => state.fork.entity.value;
const sortByStateSelector = (state) => state.fork.sortBy;
export function SortByOption({ disabled, hasDataLoaded }) {
  const ref = useRef();

  const entityValue = useReportViewState(entityValueSelector);
  const { value: sortByValues } = useReportViewState(sortByStateSelector);

  const { isOpen, open, close } = useDisclosure();

  return (
    <>
      <Tooltip title={!hasDataLoaded ? 'Fetch all the data to sort' : ''}>
        <Badge
          badgeContent={sortByValues.length}
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiBadge-badge': {
              height: '20px',
              width: '20px',
              p: 0,
              top: 5,
              left: 5,
            },
            ml: 1,
          }}
        >
          <Button
            disabled={!entityValue || disabled || !hasDataLoaded}
            ref={ref}
            onClick={open}
            size="small"
            variant="outlined"
            color="primary"
          >
            Sort By
          </Button>
        </Badge>
      </Tooltip>
      <PopoverMenu isOpen={isOpen} close={close} anchorEl={ref.current}>
        <SortByMenu />
      </PopoverMenu>
    </>
  );
}

function SortByMenu() {
  const {
    options: sortByOptions,
    value: sortByValues,
    flags: sortByFlags,
  } = useReportViewState(sortByStateSelector);

  const { handleChangeSort, handleRemoveSort, handleToggleSortFlag } = reportViewStateHandlers;

  return (
    <Box minWidth={400} maxWidth={680} maxHeight={300}>
      <Autocomplete
        multiple
        size="small"
        limitTags={2}
        sx={{ px: 1 }}
        fullWidth
        autoHighlight
        autoComplete
        openOnFocus
        ListboxComponent={AutocompleteVirtualizedListbox}
        filterOptions={autoCompleteFilterOptions}
        options={sortByOptions}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(columnOption) => columnOption.name}
        value={sortByValues}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              color="primary"
              label={option.name}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Columns to sort by"
            {...commonTextFieldProps}
          />
        )}
        renderOption={(props, option, { inputValue }) => (
          <li {...props}>
            <AutocompleteOption
              label={option.label}
              name={option.name}
              inputValue={inputValue}
              adornment={option.isKey && !option.name.includes('/') ? <MdVpnKey /> : null}
            />
          </li>
        )}
        onChange={handleChangeSort}
      />
      <Stack p={1} width={680} maxHeight={200} sx={{ overflowY: 'auto' }}>
        {sortByValues.map((selectedColumn, index) => (
          <Stack
            key={selectedColumn.name}
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            component={ListItem}
          >
            <Stack direction="row" alignItems="center">
              <IconButton size="small" onClick={handleRemoveSort(index, selectedColumn.name)}>
                <MdDelete color="rgb(249, 147, 150)" />
              </IconButton>
              <Typography ml={1}>{selectedColumn.name}</Typography>
            </Stack>
            <ToggleButtonGroup
              value={sortByFlags[selectedColumn.name]}
              onChange={handleToggleSortFlag(selectedColumn.name)}
              exclusive
              aria-label="column order by flag"
              size="small"
              sx={(theme) => ({
                height: theme.spacing(2.5),
              })}
            >
              <ToggleButton
                size="small"
                value={ENUMS.ORDER_BY_FLAG.ASC}
                aria-label="ascending"
                sx={(theme) => ({
                  width: '100%',
                  fontSize: 10,
                  fontWeight: 600,

                  '&.Mui-selected': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.primary.main,
                  },
                })}
              >
                <MdArrowDownward />
                ASC
              </ToggleButton>
              <ToggleButton
                size="small"
                value={ENUMS.ORDER_BY_FLAG.DESC}
                aria-label="descending"
                sx={(theme) => ({
                  width: '100%',
                  fontSize: 10,
                  fontWeight: 600,

                  '&.Mui-selected': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.primary.main,
                  },
                })}
              >
                <MdArrowUpward />
                DESC
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
