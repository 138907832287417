import { entries, get, isPlainObject, last, noop, omit, pull, values } from 'lodash-es';
import axios from 'axios';
import lf from 'lovefield';
import { nanoid } from 'nanoid';

import { formatEdmDateTime, formatEdmTime } from '../date';
import { ODATA_DATA_TYPES } from '../odata/utils';
import { dataflowApiBase, getServiceInstance } from '../service';
import { BATCH_STATE, generateDatabaseNamePrefix, initialWorkerStateMachine } from './utils';
import { LOG_TYPE } from 'components/ReportViewComponents/constants';
import { CONFIG } from '../config';

const BATCH_SIZE = 1000;
const LOVEFIELD_DATA_TYPES = {
  [ODATA_DATA_TYPES['Edm.Binary']]: lf.Type.STRING,
  [ODATA_DATA_TYPES['Edm.Boolean']]: lf.Type.BOOLEAN,
  [ODATA_DATA_TYPES['Edm.Byte']]: lf.Type.NUMBER,
  [ODATA_DATA_TYPES['Edm.DateTime']]: lf.Type.DATE_TIME,
  [ODATA_DATA_TYPES['Edm.DateTimeOffset']]: lf.Type.DATE_TIME,
  [ODATA_DATA_TYPES['Edm.Decimal']]: lf.Type.NUMBER,
  [ODATA_DATA_TYPES['Edm.Double']]: lf.Type.NUMBER,
  [ODATA_DATA_TYPES['Edm.Guid']]: lf.Type.STRING,
  [ODATA_DATA_TYPES['Edm.Int16']]: lf.Type.INTEGER,
  [ODATA_DATA_TYPES['Edm.Int32']]: lf.Type.INTEGER,
  [ODATA_DATA_TYPES['Edm.Int64']]: lf.Type.INTEGER,
  [ODATA_DATA_TYPES['Edm.SByte']]: lf.Type.NUMBER,
  [ODATA_DATA_TYPES['Edm.Single']]: lf.Type.NUMBER,
  [ODATA_DATA_TYPES['Edm.String']]: lf.Type.STRING,
  [ODATA_DATA_TYPES['Edm.Time']]: lf.Type.DATE_TIME,
};
const LOVEFIELD_ORDER_DIRECTIONS = {
  desc: lf.Order.DESC,
  asc: lf.Order.ASC,
};

let dbInstance;
let schemaSnapshot;
let rootPrimaryKeys = [];
let propertiesWithDateTimeDataType = {};
let propertiesWithIntegerOrFloatDataType = [];
let propertiesWithBooleanDataType = {};
let arePendingBatchesTerminated = false;

let sharedRequestCancelTokenSourceList = [];

export async function cancelPendingRequests(reason) {
  sharedRequestCancelTokenSourceList.forEach((source) => source?.cancel(reason));
  sharedRequestCancelTokenSourceList = [];
  arePendingBatchesTerminated = true;
}

export async function fetchRecordsCount({ connectionId, queryUrl, tenantId, authToken }) {
  const tokenSource = axios.CancelToken.source();
  sharedRequestCancelTokenSourceList.push(tokenSource);

  const $countUrl = createNewUrlInstance(queryUrl);
  $countUrl.searchParams.delete('$expand');
  $countUrl.pathname = $countUrl.pathname.concat('/$count');

  let recordsCount = 0;
  try {
    recordsCount = await getServiceInstance(tenantId, { isWorker: true, authToken }).post(
      `${dataflowApiBase}/connection/${connectionId}/proxy`,
      {
        skipLog: true,
        url: $countUrl,
        type: 'xml',
      },
      {
        cancelToken: tokenSource.token,
      }
    );
  } catch (error) {
    if (axios.isCancel(error)) {
      return error;
    }
  }

  return recordsCount;
}

const getWorkerStateMachine = () => {
  let state = initialWorkerStateMachine;

  return {
    actions: {
      reset: () => {
        state = initialWorkerStateMachine;
      },
      getNoOfBatches: () => state.noOfBatches,
      getBatchState: (batchIndex) => {
        return state.batches[batchIndex]?.state ?? null;
      },
      setBatchState: (batchIndex, batchState) => {
        const execute = (_batchState) => {
          state.changeId = nanoid();
          if (state.batches[batchIndex]) {
            state.batches[batchIndex].state = _batchState;
          }
        };

        if (batchState) {
          execute(batchState);
        } else {
          return execute;
        }
      },
      initializeBatches: (noOfBatches, recordsCount) => {
        state.changeId = nanoid();
        state.noOfBatches = noOfBatches;
        state.batches = Array(noOfBatches)
          .fill(1)
          .map(() => ({ state: BATCH_STATE.IDLE }));
        state.recordsFetched = 0;
        state.totalRecords = recordsCount;
      },
      setActiveRecordsCount: (count) => {
        state.recordsFetched = state.recordsFetched + count;
      },
    },
    state,
  };
};

export const getBatchState = () => {
  const batchState = getWorkerStateMachine();

  return batchState.state;
};

export async function createDBInstance({
  dbName,
  entity,
  expandedNavigationProps,
  expandOptionsMap,
  schema,
  recordsCount,
  connectionId,
  tenantId,
  queryUrl,
  tabId,
  authToken,
  showPicklistValues,
}) {
  try {
    await purgeIDB({ tabId });

    bustCache();

    schemaSnapshot = schema;
    rootPrimaryKeys = entity.property.filter((prop) => prop.isKey);

    const schemaBuilder = lf.schema.create(dbName, 1);

    const entitySelector = `${entity.namespace}.${entity.name}`;
    const entityType = schema.entityTypesMap[entitySelector];

    createTables({
      tableName: entityType.name,
      schemaBuilder,
      entityType,
      expandedNavigationProps,
      expandOptionsMap,
    });

    return schemaBuilder.connect().then(async (db) => {
      dbInstance = db;
      arePendingBatchesTerminated = false;

      const workerStateMachine = getWorkerStateMachine();

      // Get number of batches
      const noOfBatches = Math.ceil(recordsCount / BATCH_SIZE);
      workerStateMachine.actions.initializeBatches(noOfBatches, recordsCount);

      const lastBatchCancelTokenSource = last(sharedRequestCancelTokenSourceList);

      let batchIndex = 0;
      const startSnapshotFetch = async (batchUrl, index) => {
        const batchStateSetter = workerStateMachine.actions.setBatchState(index);
        batchStateSetter(BATCH_STATE.FETCHING);

        try {
          const response = await getServiceInstance(tenantId, { isWorker: true, authToken }).post(
            `${dataflowApiBase}/connection/${connectionId}/proxy`,
            {
              url: batchUrl.toString(),
              skipLog: batchIndex !== 0,
              type: LOG_TYPE.REPORT_VIEW,
              additional_info: {
                show_picklist_value: showPicklistValues,
              },
            },
            {
              cancelToken: lastBatchCancelTokenSource?.token,
            }
          );

          if (!response || !response?.d) {
            batchStateSetter(BATCH_STATE.FETCH_FAILED);
            throw new Error('Failed to fetch data');
          }

          batchStateSetter(BATCH_STATE.IMPORTING);

          let rawRecords = [];
          if (Array.isArray(response.d)) {
            rawRecords = response.d;
          }

          if (Array.isArray(response.d?.results)) {
            rawRecords = response.d?.results;
          }

          workerStateMachine.actions.setActiveRecordsCount(rawRecords.length);

          if (!rawRecords.length) {
            batchStateSetter(BATCH_STATE.READY);

            if (arePendingBatchesTerminated) {
              return arePendingBatchesTerminated;
            }

            return;
          }

          try {
            await importToDatabase(entity, rawRecords);
          } catch (error) {
            batchStateSetter(BATCH_STATE.IMPORT_FAILED);

            throw new Error(error);
          }
          batchStateSetter(BATCH_STATE.READY);

          if (response.d.hasOwnProperty('__next')) {
            const selectedTable = dbInstance.getSchema().table(entity.name);
            const savedData = await dbInstance
              .select(selectedTable.lf_dataflow_keyHash)
              .from(selectedTable)
              .exec();

            if (savedData.length >= CONFIG.DATA_FETCH_LIMIT) {
              return;
            }

            batchIndex += 1;
            await startSnapshotFetch(response.d.__next, batchIndex);
          }
        } catch (error) {
          batchStateSetter(BATCH_STATE.FETCH_FAILED);

          if (error instanceof Error) {
            throw new Error(error.message);
          }

          const { error: _error } = error;

          if (_error) {
            throw new Error(JSON.stringify(_error));
          }

          throw new Error(JSON.stringify(error));
        }
      };

      const url = createNewUrlInstance(queryUrl);
      url.searchParams.set('paging', 'snapshot');
      url.searchParams.set('$format', 'json');
      return await startSnapshotFetch(url, batchIndex);
    });
  } catch (error) {
    console.error(`Failed to create database: ${dbName}`, error);
  }
}

async function importToDatabase(entity, rawRecords) {
  let nestedTables = {};
  const records = rawRecords.map((record) => {
    const flattenedRecord = {};
    flattenRecord(omit(record, '__metadata'), flattenedRecord);

    return flattenedRecord;
  });

  const pushDataToTable = async ({
    tableName,
    entity,
    records,
    isNested = false,
    parentTableName = '',
  }) => {
    const _tableName = isNested
      ? getTableName(`nested_${parentTableName}_${tableName}`)
      : tableName;

    const table = dbInstance.getSchema().table(_tableName);
    const rows = [];

    if (!isNested) {
      records.forEach((row) => {
        const primaryKeys = rootPrimaryKeys.reduce((acc, key) => {
          return {
            ...acc,
            [key.name]: row[key.name],
          };
        }, {});

        const pk_keyHash = JSON.stringify(primaryKeys);

        const _record = omit(row, '__metadata');

        const formattedBooleanProperties = entries(propertiesWithBooleanDataType).reduce(
          (acc, [propertyName]) => {
            let boolValue = get(_record, propertyName);
            if (boolValue === false) {
              boolValue = 'No';
            } else if (boolValue === true) {
              boolValue = 'Yes';
            }

            return { ...acc, [propertyName]: boolValue };
          },
          {}
        );

        const formattedDateProperties = entries(propertiesWithDateTimeDataType).reduce(
          (acc, [propertyName, { type }]) => {
            const dateString = get(_record, propertyName);
            const formattedDate = getFormattedDateTimeValue(dateString, type);

            return { ...acc, [propertyName]: formattedDate };
          },
          {}
        );
        const formattedIntegerOrFloatProperties = propertiesWithIntegerOrFloatDataType.reduce(
          (acc, propertyName) => {
            const value = get(_record, propertyName);

            return { ...acc, [propertyName]: Number(value) };
          },
          {}
        );

        const rowData = {
          ..._record,
          ...formattedDateProperties,
          ...formattedIntegerOrFloatProperties,
          ...formattedBooleanProperties,
          _INT_UQ_ID: nanoid(),
          lf_dataflow_keyHash: pk_keyHash,
        };

        rows.push(table.createRow(rowData));

        entries(row).forEach(([propertyName, propertyData]) => {
          if (Array.isArray(propertyData)) {
            const currentNavProp = entity.navigationProperty.find(
              (navProp) => navProp.name === propertyName
            );

            nestedTables = {
              [propertyName]: {
                tableName: currentNavProp.name,
                parentTableName: _tableName,
                isNested: true,
                data: [
                  ...(nestedTables[propertyName] ? nestedTables[propertyName].data : []),
                  {
                    records: propertyData,
                    lf_dataflow_fk_keyHash: pk_keyHash,
                  },
                ],
              },
            };
          }
        });
      });
    } else {
      records.forEach((row) => {
        row.records.forEach((record) => {
          const _record = omit(record, '__metadata');

          const formattedBooleanProperties = entries(propertiesWithBooleanDataType).reduce(
            (acc, [propertyName]) => {
              let boolValue = get(_record, propertyName);
              if (boolValue === false) {
                boolValue = 'No';
              } else if (boolValue === true) {
                boolValue = 'Yes';
              }

              return { ...acc, [propertyName]: boolValue };
            },
            {}
          );

          const formattedDateProperties = entries(propertiesWithDateTimeDataType).reduce(
            (acc, [propertyName, { type }]) => {
              const dateString = get(_record, propertyName);
              if (dateString) {
                const formattedDate = getFormattedDateTimeValue(dateString, type);
                return { ...acc, [propertyName]: formattedDate };
              } else {
                return acc;
              }
            },
            {}
          );

          const formattedIntegerOrFloatProperties = propertiesWithIntegerOrFloatDataType.reduce(
            (acc, propertyName) => {
              const value = get(_record, propertyName);

              return { ...acc, [propertyName]: Number(value) };
            },
            {}
          );

          const rowData = {
            ..._record,
            ...formattedDateProperties,
            ...formattedIntegerOrFloatProperties,
            ...formattedBooleanProperties,
            _INT_UQ_ID: nanoid(),
            [`${parentTableName}_lf_dataflow_keyHash`]: row.lf_dataflow_fk_keyHash,
          };

          rows.push(table.createRow(rowData));
        });
      });
    }

    await dbInstance.insertOrReplace().into(table).values(rows).exec();
  };

  await pushDataToTable({ tableName: entity.name, entity, records });

  await Promise.all(
    values(nestedTables).map(
      async (nestedTable) =>
        await pushDataToTable({
          tableName: nestedTable.tableName,
          records: nestedTable.data,
          isNested: nestedTable.isNested,
          parentTableName: nestedTable.parentTableName,
        })
    )
  );
}

function createDataQuery(parsedState) {
  const selectedEntity = parsedState.entity.value.name;
  const selectedTable = dbInstance.getSchema().table(selectedEntity);
  const orderBy = entries(parsedState.sortBy.flags);
  const expand = parsedState.expand;

  const query = dbInstance.select().from(selectedTable);

  // When report view is open, we don't want to do the LEFT OUTER JOIN.
  if (expand.value.length > 0) {
    expand.value.forEach((propertyName) => {
      const propertyData = expand.optionsMap[propertyName];
      if (propertyData.multiplicity === '*') {
        const nestedTable = getNestedTableInstance(propertyData.name, selectedEntity);

        query.leftOuterJoin(
          nestedTable,
          selectedTable.lf_dataflow_keyHash.eq(nestedTable[`${selectedEntity}_lf_dataflow_keyHash`])
        );
      }
    });
  }

  if (orderBy.length > 0) {
    orderBy.forEach(([name, order]) => {
      let propertyName = name;

      if (name.includes('/')) {
        propertyName = name.replace('/', '_');
      }

      query.orderBy(selectedTable[propertyName], LOVEFIELD_ORDER_DIRECTIONS[order]);
    });
  }

  return query;
}

const createCountQuery = (parsedState) => {
  const selectedEntity = parsedState.entity.value.name;
  const expand = parsedState.expand;
  const selectedTable = dbInstance.getSchema().table(selectedEntity);
  const query = dbInstance.select(selectedTable.lf_dataflow_keyHash).from(selectedTable);

  // Expand is required in createCountQuery incase a filter based on the n-multiplicity property is added
  if (expand.value.length > 0) {
    expand.value.forEach((propertyName) => {
      const propertyData = expand.optionsMap[propertyName];
      if (propertyData.multiplicity === '*') {
        const nestedTable = getNestedTableInstance(propertyData.name, selectedEntity);

        query.leftOuterJoin(
          nestedTable,
          selectedTable.lf_dataflow_keyHash.eq(nestedTable[`${selectedEntity}_lf_dataflow_keyHash`])
        );
      }
    });
  }

  return query;
};

export async function runPagination(limit, skip, currentState) {
  const parsedState = JSON.parse(currentState);

  const dataQuery = createDataQuery(parsedState);
  const countQuery = createCountQuery(parsedState);

  // Get a transaction object first.
  var txn = dbInstance.createTransaction();

  // exec in order: query 1 first then query2, guaranteed snapshot
  const [queryData, countData] = await txn.exec([dataQuery.limit(limit).skip(skip), countQuery]);
  return { queryData, totalCount: countData.length };
}

// Helper Functions
const bustCache = () => {
  schemaSnapshot = {};
  rootPrimaryKeys = [];
  propertiesWithDateTimeDataType = {};
  propertiesWithIntegerOrFloatDataType = [];
};

function deleteDB(dbName) {
  return new Promise((resolve, reject) => {
    const dbDeleteRequest = indexedDB.deleteDatabase(dbName);
    dbDeleteRequest.onerror = () => {
      reject(`Failed to delete database: ${dbName}`);
    };
    dbDeleteRequest.onsuccess = () => {
      resolve(`Deleted database ${dbName}`);
    };
    dbDeleteRequest.onblocked = () => {
      reject(`Failed to delete database: ${dbName}`);
    };
  });
}

export async function purgeIDB({ tabId }) {
  if (dbInstance) {
    dbInstance.close();
  }
  const databases = await indexedDB.databases();
  await Promise.all(
    databases.map(async (db) => {
      if (db.name.startsWith(generateDatabaseNamePrefix({ tabId }))) {
        await deleteDB(db.name);
      }
    })
  ).catch(noop);
}

const createNewUrlInstance = (_url) => {
  const url = new URL(_url);
  url.searchParams.delete('$top');
  url.searchParams.delete('$skip');
  url.searchParams.delete('$orderby');
  url.searchParams.delete('$format');
  url.searchParams.delete('$inlinecount');
  url.searchParams.delete('paging');

  return url;
};

const getTableName = (nameString) => nameString.replace('.', '_');

const createTables = ({
  tableName,
  schemaBuilder,
  entityType,
  isNested = false,
  parentTableName = '',
  expandedNavigationProps = [],
  expandOptionsMap = {},
}) => {
  const primaryKeys = ['_INT_UQ_ID'];
  const nullableProperties = [];

  const _tableName = isNested ? getTableName(`nested_${parentTableName}_${tableName}`) : tableName;

  const tableBuilder = schemaBuilder
    .createTable(_tableName)
    .addColumn(
      isNested ? `${parentTableName}_lf_dataflow_keyHash` : 'lf_dataflow_keyHash',
      LOVEFIELD_DATA_TYPES['Edm.String']
    )
    .addColumn('_INT_UQ_ID', LOVEFIELD_DATA_TYPES['Edm.Int16']);

  entityType.property.forEach((prop) => {
    if (prop.isKey) {
      primaryKeys.push(prop.name);
    } else if (prop.nullable) {
      nullableProperties.push(prop.name);
    }

    tableBuilder.addColumn(prop.name, LOVEFIELD_DATA_TYPES[prop.type]);

    if (
      prop.type === ODATA_DATA_TYPES['Edm.DateTime'] ||
      prop.type === ODATA_DATA_TYPES['Edm.DateTimeOffset'] ||
      prop.type === ODATA_DATA_TYPES['Edm.Time']
    ) {
      propertiesWithDateTimeDataType = {
        ...propertiesWithDateTimeDataType,
        [prop.name]: prop,
      };
    } else if (
      prop.type === ODATA_DATA_TYPES['Edm.Decimal'] ||
      prop.type === ODATA_DATA_TYPES['Edm.Double'] ||
      prop.type === ODATA_DATA_TYPES['Edm.Int16'] ||
      prop.type === ODATA_DATA_TYPES['Edm.Int32'] ||
      prop.type === ODATA_DATA_TYPES['Edm.Int64']
    ) {
      propertiesWithIntegerOrFloatDataType.push(prop.name);
    } else if (prop.type === ODATA_DATA_TYPES['Edm.Boolean']) {
      propertiesWithBooleanDataType = {
        ...propertiesWithBooleanDataType,
        [prop.name]: prop,
      };
    }
  });

  if (!isNested) {
    expandedNavigationProps.forEach((navProp) => {
      const navigationProperty = expandOptionsMap[navProp];

      const association = schemaSnapshot.associationsMap[navigationProperty.relationship];
      const toRole = association.end.find(({ role }) => role === navigationProperty.toRole);

      const expandedEnity = schemaSnapshot.entityTypesMap[toRole.type];

      // Create seperate table for each N-multiplicity property
      if (navigationProperty.multiplicity === '*') {
        createTables({
          tableName: navigationProperty.name,
          schemaBuilder,
          entityType: expandedEnity,
          isNested: true,
          parentTableName: entityType.name,
        });
      }
      // Flatten out the 1-multiplicity property
      else {
        expandedEnity.property.forEach((prop) => {
          if (prop.nullable) {
            nullableProperties.push(`${navigationProperty.name}_${prop.name}`);
          }

          tableBuilder.addColumn(
            `${navigationProperty.name}_${prop.name}`,
            LOVEFIELD_DATA_TYPES[prop.type]
          );

          if (
            prop.type === ODATA_DATA_TYPES['Edm.DateTime'] ||
            prop.type === ODATA_DATA_TYPES['Edm.DateTimeOffset'] ||
            prop.type === ODATA_DATA_TYPES['Edm.Time']
          ) {
            propertiesWithDateTimeDataType = {
              ...propertiesWithDateTimeDataType,
              [`${navigationProperty.name}_${prop.name}`]: prop,
            };
          } else if (
            prop.type === ODATA_DATA_TYPES['Edm.Decimal'] ||
            prop.type === ODATA_DATA_TYPES['Edm.Double'] ||
            prop.type === ODATA_DATA_TYPES['Edm.Int16'] ||
            prop.type === ODATA_DATA_TYPES['Edm.Int32'] ||
            prop.type === ODATA_DATA_TYPES['Edm.Int64']
          ) {
            propertiesWithIntegerOrFloatDataType.push(prop.name);
          } else if (prop.type === ODATA_DATA_TYPES['Edm.Boolean']) {
            propertiesWithBooleanDataType = {
              ...propertiesWithBooleanDataType,
              [prop.name]: prop,
            };
          }
        });
      }
    });

    tableBuilder.addUnique('uq_lf_dataflow_keyHash', ['lf_dataflow_keyHash']);
  } else {
    tableBuilder.addForeignKey(`lf_dataflow_fk_keyHash`, {
      local: `${parentTableName}_lf_dataflow_keyHash`,
      ref: `${parentTableName}.lf_dataflow_keyHash`,
    });
  }

  tableBuilder.addPrimaryKey(primaryKeys).addNullable(nullableProperties);
};

function flattenRecord(record, rootObject, keyPrefix = '') {
  entries(record).forEach(([key, value]) => {
    if (isPlainObject(value)) {
      if (value.__metadata) {
        flattenRecord(omit(value, '__metadata'), rootObject, `${key}_`);
      } else if (keyPrefix === '' && value.results && Array.isArray(value.results)) {
        rootObject[`${keyPrefix}${key}`] = value.results;
      }
    } else {
      rootObject[`${keyPrefix}${key}`] = value;
    }
  });
}

const getFormattedDateTimeValue = (dateString, type) => {
  if (
    type === ODATA_DATA_TYPES['Edm.DateTime'] ||
    type === ODATA_DATA_TYPES['Edm.DateTimeOffset']
  ) {
    return dateString ? formatEdmDateTime({ dateTime: dateString, returnObj: true }) : null;
  } else if (type === ODATA_DATA_TYPES['Edm.Time']) {
    return dateString ? formatEdmTime({ time: dateString, returnObj: true }) : null;
  }
};

const getNestedTableInstance = (tableName, rootTableName) => {
  return dbInstance.getSchema().table(getTableName(`nested_${rootTableName}_${tableName}`));
};

const getFilteredQuery = ({ rule, selectedTable, expand, rootTableName }) => {
  let propertyName = rule.property.name;
  let _selectedTable = selectedTable;

  // If the query is against an expanded n-multiplicty navProperty, then we change the selectTable and the propertyName
  if (propertyName.includes('/')) {
    const [nestedTableName, columnName] = propertyName.split('/');
    const propertyData = expand.optionsMap[nestedTableName];
    if (propertyData.multiplicity === '*') {
      _selectedTable = getNestedTableInstance(propertyData.name, rootTableName);
      propertyName = columnName;
    } else {
      propertyName = `${nestedTableName}_${columnName}`;
    }
  }

  if (!_selectedTable[propertyName]) {
    return;
  }

  const filter = rule.filter.key;
  const param = rule.param;
  const type = rule.property.type;
  let regx = '';

  switch (filter) {
    case 'isTrue': {
      return _selectedTable[propertyName].eq(true);
    }
    case 'isFalse': {
      return _selectedTable[propertyName].eq(false);
    }
    case 'eq': {
      return _selectedTable[propertyName].eq(param);
    }
    case 'ne': {
      return _selectedTable[propertyName].neq(param);
    }
    case 'gt': {
      return _selectedTable[propertyName].gt(param);
    }
    case 'ge': {
      return _selectedTable[propertyName].gte(param);
    }
    case 'lt': {
      return _selectedTable[propertyName].lt(param);
    }
    case 'le': {
      return _selectedTable[propertyName].lte(param);
    }
    case 'in': {
      const _param = param.split(';').map((value) => {
        if (type === ODATA_DATA_TYPES['Edm.String']) {
          return value;
        }

        return Number(value);
      });

      return _selectedTable[propertyName].in(_param);
    }
    case 'roundEq': {
      const lowerBound = param - 0.5;
      const upperBound = param + 0.5;

      return lf.op.and(
        _selectedTable[propertyName].gte(lowerBound),
        _selectedTable[propertyName].lt(upperBound)
      );
    }
    case 'floorEq': {
      const lowerBound = param;
      const upperBound = param + 1;

      return lf.op.and(
        _selectedTable[propertyName].gte(lowerBound),
        _selectedTable[propertyName].lt(upperBound)
      );
    }
    case 'ceilingEq': {
      const lowerBound = param - 1;
      const upperBound = param;

      return lf.op.and(
        _selectedTable[propertyName].gt(lowerBound),
        _selectedTable[propertyName].lte(upperBound)
      );
    }

    case 'caseInsensitiveEq': {
      regx = new RegExp(`${param}\\b`, 'gi');
      return _selectedTable[propertyName].match(regx);
    }
    case 'caseInsensitiveNe': {
      regx = new RegExp(`[^${param}]`, 'gi');
      return _selectedTable[propertyName].match(regx);
    }
    case 'startsWith': {
      regx = new RegExp(`^${param}`, 'g');
      return _selectedTable[propertyName].match(regx);
    }
    case 'notStartsWith': {
      regx = new RegExp(`^[^${param}]`, 'g');
      return _selectedTable[propertyName].match(regx);
    }
    case 'endsWith': {
      regx = new RegExp(`${param}$`, 'g');
      return _selectedTable[propertyName].match(regx);
    }
    case 'notEndsWith': {
      regx = new RegExp(`[^${param}]$`, 'g');
      return _selectedTable[propertyName].match(regx);
    }
    case 'contains': {
      regx = new RegExp(`${param}`, 'g');
      return _selectedTable[propertyName].match(regx);
    }
    case 'notContains': {
      regx = new RegExp(`^((?!${param}).)*$`, 'g');
      return _selectedTable[propertyName].match(regx);
    }
    case 'datetimeEq': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].eq(utcDate);
    }
    case 'datetimeNe': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].neq(utcDate);
    }
    case 'datetimeEqOrBefore': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].lte(utcDate);
    }
    case 'datetimeBefore': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].lt(utcDate);
    }
    case 'datetimeAfter': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].gt(utcDate);
    }
    case 'datetimeEqOrAfter': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].gte(utcDate);
    }
    case 'timeEq': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].eq(utcDate);
    }
    case 'timeNe': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].neq(utcDate);
    }
    case 'timeEqOrBefore': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].lte(utcDate);
    }
    case 'timeBefore': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].lt(utcDate);
    }
    case 'timeAfter': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].gt(utcDate);
    }
    case 'timeEqOrAfter': {
      const utcDate = formatEdmDateTime({ dateTime: param, returnObj: true });
      return _selectedTable[propertyName].gte(utcDate);
    }

    default: {
      return;
    }
  }
};

const getWherePredicate = ({ ruleOrGroup, selectedTable, expand, rootTableName }) => {
  const operator = ruleOrGroup.operator;

  const lovefieldConsumableRules = ruleOrGroup.rules.map((rule) => {
    if (rule.rules) {
      return getWherePredicate({
        ruleOrGroup: rule,
        selectedTable,
        expand,
        rootTableName,
      });
    }
    return getFilteredQuery({
      rule,
      selectedTable,
      expand,
      rootTableName,
    });
  });

  // This pull is added here to handle the unsupported filters. Unsupported filters like lengthGt will return undefined when passed to the
  // getFilteredQuery fn so we want to pull undefined out of the array
  const filteredRules = pull(lovefieldConsumableRules, undefined);

  if (filteredRules.length > 0) {
    if (operator === 'or') {
      return lf.op.or(...filteredRules);
    } else if (operator === 'and') {
      return lf.op.and(...filteredRules);
    }
  }
  return;
};
