/**
 * @file
 *
 * this file contains all the code needed for building a query from form state of the query builder UI
 */
import { clamp, keys, uniq } from 'lodash-es';
import buildQuery from 'odata-query';
import memoizeOne from 'memoize-one';
import dayjs from 'dayjs';

import { cast, validate } from '../utils';
import { ODATA_DATA_TYPES, ENUMS, getEntitySetName } from './utils';
import { SYSTEMS } from '../constants';
import { PAGINATION } from '../state/queryBuilder';
import { customFormats, getUTCOffsetInODataFormat } from '../date';
import { checkIsVariableValid } from 'hooks/useDateTimePicker';

/**
 * all the integer types supported by OData
 */
export const intTypes = [
  ODATA_DATA_TYPES['Edm.Int16'],
  ODATA_DATA_TYPES['Edm.Int32'],
  ODATA_DATA_TYPES['Edm.Int64'],
];
/**
 * all the floating point types supported by OData
 */
export const floatingPointTypes = [
  ODATA_DATA_TYPES['Edm.Single'],
  ODATA_DATA_TYPES['Edm.Double'],
  ODATA_DATA_TYPES['Edm.Decimal'],
];

export const dateTimeTypes = [
  ODATA_DATA_TYPES['Edm.DateTime'],
  ODATA_DATA_TYPES['Edm.DateTimeOffset'],
];

const today = new Date();
const dateParts = {
  year: {
    min: 1753,
    max: 9999,
    placeholder: today.getFullYear().toString(),
  },
  month: {
    min: 1,
    max: 12,
    placeholder: clamp(today.getMonth() + 1, 2, 11).toString(),
  },
  day: {
    min: 1,
    max: 31,
    placeholder: clamp(today.getDate() + 1, 2, 30).toString(),
  },
  hour: {
    min: 0,
    max: 23,
    placeholder: clamp(today.getHours() + 1, 1, 22).toString(),
  },
  minute: {
    min: 0,
    max: 59,
    placeholder: clamp(today.getHours() + 1, 1, 58).toString(),
  },
  second: {
    min: 0,
    max: 59,
    placeholder: clamp(today.getHours() + 1, 1, 58).toString(),
  },
};

export const hintTexts = {
  int: {
    helperText: 'Integer value. Ex - 10',
    placeholder: '10',
  },
  float: {
    helperText: 'Decimal value. Ex - 10 or 12.5',
    placeholder: '12.5',
  },
  string: {
    helperText: 'Text / String value. Ex - user',
    placeholder: 'text',
  },
  intList: {
    helperText: 'Integer values (separated by ;). Ex - 1;3;10;',
    placeholder: '1;3;10',
  },
  floatList: {
    helperText: 'Decimal values (separated by ;). Ex - 4.2;10;12.5',
    placeholder: '4.2;10;12.5',
  },
  stringList: {
    helperText: 'Text / String values (separated by ;). Ex - admin;user;',
    placeholder: 'text;string',
  },
  uuid: {
    helperText: 'UUID or GUID. Ex - 12345678-aaaa-bbbb-cccc-ddddeeeeffff',
    placeholder: '12345678-aaaa-bbbb-cccc-ddddeeeeffff',
  },
  datetime: {
    helperText: 'Datetime - yyyy-mm-dd hh:mm:ss. Ex - 2000-12-01 17:35:59',
    placeholder: '2000-12-01 17:35:59 / $TODAY$',
  },
  datetimeVariable: {
    helperText: 'Datetime - $VARIABLE_NAME$. Ex - $TODAY$',
    placeholder: '2000-12-01 17:35:59 / $TODAY$',
  },
  date: {
    helperText: 'Date - yyyy-mm-dd. Ex - 2000-12-01',
    placeholder: '2000-12-01',
  },
  time: {
    helperText: 'Time - hh:mm:ss. Ex - 17:35:59',
    placeholder: '17:35:59',
  },
};

const addRequiredTagToHelperText = (hint) => ({
  ...hint,
  helperText: '[Required] ' + hint.helperText,
});

/**
 * @function
 *
 * function to sanitize the parameter value of filter operator based on the property type
 */
const sanitizeParamValueBasedOnPropertyType = ({ property, param }) => {
  if (intTypes.includes(property.type)) {
    return cast.int(param);
  } else if (floatingPointTypes.includes(property.type)) {
    return cast.float(param);
  }

  return cast.string(param);
};

/**
 * @function
 *
 * function to sanitize a comma separated param value based on the property type
 */
const sanitizeParamListValueBasedOnPropertyType = ({ property, param }) => {
  if (param === null || param === '' || param === undefined) {
    return null;
  }

  const chunks = param.split(';');

  const paramsList = uniq(
    chunks
      .filter((p, i) => i + 1 < chunks.length || p)
      .map((_param) => sanitizeParamValueBasedOnPropertyType({ property, param: _param }))
  );

  return property.nullable === 'false' ? paramsList.filter((p) => p !== null) : paramsList;
};

export const relativeDateTimeOptions = {
  CUSTOM: {
    key: 'Custom',
    name: 'Custom',
    label: 'Custom',
    type: undefined,
    translate: () => undefined,
    getDateObj: () => undefined,
  },
  $TODAY$: {
    key: '$TODAY$',
    name: '$TODAY$',
    label: 'Current Date',
    type: 'relative',
    translate: (format) => cast.date(dayjs()).format(format),
    getDateObj: () => cast.date(dayjs()),
  },
  $YESTERDAY$: {
    key: '$YESTERDAY$',
    name: '$YESTERDAY$',
    label: "Yesterday's Date",
    type: 'relative',
    translate: (format) => cast.date(dayjs().subtract(1, 'day')).format(format),
    getDateObj: () => cast.date(dayjs().subtract(1, 'day')),
  },
  $FDOCM$: {
    key: '$FDOCM$',
    name: '$FDOCM$',
    label: 'First day of current month',
    type: 'relative',
    translate: (format) => cast.date(dayjs().startOf('month')).format(format),
    getDateObj: () => cast.date(dayjs().startOf('month')),
  },
  $FDOCY$: {
    key: '$FDOCY$',
    name: '$FDOCY$',
    label: 'First day of current year',
    type: 'relative',
    translate: (format) => cast.date(dayjs().startOf('year')).format(format),
    getDateObj: () => cast.date(dayjs().startOf('year')),
  },
  $LDOPM$: {
    key: '$LDOPM$',
    name: '$LDOPM$',
    label: 'Last day of previous month',
    type: 'relative',
    translate: (format) => cast.date(dayjs().subtract(1, 'month').endOf('month')).format(format),
    getDateObj: () => cast.date(dayjs().subtract(1, 'month').endOf('month')),
  },
  $LDOPY$: {
    key: '$LDOPY$',
    name: '$LDOPY$',
    label: 'Last day of previous year',
    type: 'relative',
    translate: (format) => cast.date(dayjs().subtract(1, 'year').endOf('year')).format(format),
    getDateObj: () => cast.date(dayjs().subtract(1, 'year').endOf('year')),
  },
  $LOWDATE$: {
    key: '$LOWDATE$',
    name: '$LOWDATE$',
    label: 'Low Date - 01/01/1900',
    type: 'relative',
    translate: (format) => cast.date(dayjs('01/01/1900')).format(format),
    getDateObj: () => cast.date(dayjs('01/01/1900')),
  },
  $HIGHDATE$: {
    key: '$HIGHDATE$',
    name: '$HIGHDATE$',
    label: 'High Date - 31/12/9999',
    type: 'relative',
    translate: (format) => cast.date(dayjs('12/31/9999')).format(format),
    getDateObj: () => cast.date(dayjs('12/31/9999')),
  },
};

export const dateTimeParamOptions = [
  relativeDateTimeOptions.CUSTOM,
  relativeDateTimeOptions.$TODAY$,
  relativeDateTimeOptions.$YESTERDAY$,
  relativeDateTimeOptions.$FDOCM$,
  relativeDateTimeOptions.$LDOPM$,
  relativeDateTimeOptions.$FDOCY$,
  relativeDateTimeOptions.$LDOPY$,
  relativeDateTimeOptions.$LOWDATE$,
  relativeDateTimeOptions.$HIGHDATE$,
];

/**
 * an object that contains all the filter options possible with labels, validation function all co-located
 */
export const filterOptions = {
  isTrue: {
    key: 'isTrue',
    label: 'is true',
    getQueryFilter({ property }) {
      return { [property.name]: true };
    },
  },
  isFalse: {
    key: 'isFalse',
    label: 'is false',
    getQueryFilter({ property }) {
      return { [property.name]: false };
    },
  },

  eq: {
    key: 'eq',
    label: 'equals',
    getHints: ({ property: { type, nullable } }) => {
      const required = nullable === 'false';

      let hint;
      if (intTypes.includes(type)) {
        hint = hintTexts.int;
      } else if (floatingPointTypes.includes(type)) {
        hint = hintTexts.float;
      } else {
        hint = hintTexts.string;
      }

      return required ? addRequiredTagToHelperText(hint) : hint;
    },
    validate: ({ property: { type, nullable }, param }) => {
      const required = nullable === 'false';

      if (intTypes.includes(type)) {
        return validate.int(param, { required });
      } else if (floatingPointTypes.includes(type)) {
        return validate.float(param, { required });
      }

      return validate.string(param, { required });
    },
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null && property.nullable === 'false') {
        return;
      }

      return { [property.name]: param };
    },
  },
  ne: {
    key: 'ne',
    label: 'not equals',
    getHints: ({ property: { type, nullable } }) => {
      const required = nullable === 'false';

      let hint;
      if (intTypes.includes(type)) {
        hint = hintTexts.int;
      } else if (floatingPointTypes.includes(type)) {
        hint = hintTexts.float;
      } else {
        hint = hintTexts.string;
      }

      return required ? addRequiredTagToHelperText(hint) : hint;
    },
    validate: ({ property: { type, nullable }, param }) => {
      const required = nullable === 'false';

      if (intTypes.includes(type)) {
        return validate.int(param, { required });
      } else if (floatingPointTypes.includes(type)) {
        return validate.float(param, { required });
      }

      return validate.string(param, { required });
    },
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null && property.nullable === 'false') {
        return;
      }

      return { [property.name]: { ne: param } };
    },
  },
  gt: {
    key: 'gt',
    label: 'greater than',
    getHints: ({ property: { type } }) =>
      addRequiredTagToHelperText(intTypes.includes(type) ? hintTexts.int : hintTexts.float),
    validate: ({ property: { type }, param }) =>
      validate[intTypes.includes(type) ? 'int' : 'float'](param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null) {
        return;
      }

      return { [property.name]: { gt: param } };
    },
  },
  ge: {
    key: 'ge',
    label: 'greater than or equal to',
    getHints: ({ property: { type } }) =>
      addRequiredTagToHelperText(intTypes.includes(type) ? hintTexts.int : hintTexts.float),
    validate: ({ property: { type }, param }) =>
      validate[intTypes.includes(type) ? 'int' : 'float'](param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null) {
        return;
      }

      return { [property.name]: { ge: param } };
    },
  },
  lt: {
    key: 'lt',
    label: 'less than',
    getHints: ({ property: { type } }) =>
      addRequiredTagToHelperText(intTypes.includes(type) ? hintTexts.int : hintTexts.float),
    validate: ({ property: { type }, param }) =>
      validate[intTypes.includes(type) ? 'int' : 'float'](param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null) {
        return;
      }

      return { [property.name]: { lt: param } };
    },
  },
  le: {
    key: 'le',
    label: 'less than or equal to',
    getHints: ({ property: { type } }) =>
      addRequiredTagToHelperText(intTypes.includes(type) ? hintTexts.int : hintTexts.float),
    validate: ({ property: { type }, param }) =>
      validate[intTypes.includes(type) ? 'int' : 'float'](param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null) {
        return;
      }

      return { [property.name]: { le: param } };
    },
  },
  in: {
    key: 'in',
    label: 'in (separated by ;)',
    getHints: ({ property: { type } }) => {
      let hint;
      if (intTypes.includes(type)) {
        hint = hintTexts.intList;
      } else if (floatingPointTypes.includes(type)) {
        hint = hintTexts.floatList;
      } else {
        hint = hintTexts.stringList;
      }

      return addRequiredTagToHelperText(hint);
    },
    validate: ({ property: { type, nullable }, param }) => {
      const required = nullable === 'false';

      if (!param) {
        return false;
      }
      const delimiter = ';';
      let chunks = param.split(delimiter);
      chunks = chunks.filter((p, i) => i + 1 < chunks.length || p);

      if (intTypes.includes(type)) {
        return chunks.every((p) => validate.int(p, { required }));
      } else if (floatingPointTypes.includes(type)) {
        return chunks.every((p) => validate.float(p, { required }));
      }

      return chunks.every((p) => validate.string(p, { required }));
    },
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamListValueBasedOnPropertyType(condition);

      if (param === null || !param.length) {
        return;
      }

      return { [property.name]: { in: param } };
    },
  },

  roundEq: {
    key: 'roundEq',
    group: 'rounding',
    label: 'round equals',
    getHints: ({ property: { type } }) =>
      addRequiredTagToHelperText(intTypes.includes(type) ? hintTexts.int : hintTexts.float),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null) {
        return;
      }

      return { [`round(${property.name})`]: { le: param } };
    },
  },
  floorEq: {
    key: 'floorEq',
    group: 'rounding',
    label: 'floor equals',
    getHints: ({ property: { type } }) =>
      addRequiredTagToHelperText(intTypes.includes(type) ? hintTexts.int : hintTexts.float),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null) {
        return;
      }

      return { [`floor(${property.name})`]: { le: param } };
    },
  },
  ceilingEq: {
    key: 'ceilingEq',
    group: 'rounding',
    label: 'ceiling equals',
    getHints: ({ property: { type } }) =>
      addRequiredTagToHelperText(intTypes.includes(type) ? hintTexts.int : hintTexts.float),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = sanitizeParamValueBasedOnPropertyType(condition);

      if (param === null) {
        return;
      }

      return { [`ceiling(${property.name})`]: { le: param } };
    },
  },

  caseInsensitiveEq: {
    key: 'caseInsensitiveEq',
    label: 'case insensitive equals',
    getHints: () => addRequiredTagToHelperText(hintTexts.string),
    validate: ({ param }) => validate.string(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.string(param);

      if (param === null) {
        return;
      }

      return {
        [`tolower(${property.name})`]: {
          eq: String(param).toLowerCase(),
        },
      };
    },
  },
  caseInsensitiveNe: {
    key: 'caseInsensitiveNe',
    label: 'case insensitive not equal',
    getHints: () => addRequiredTagToHelperText(hintTexts.string),
    validate: ({ param }) => validate.string(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.string(param);

      if (param === null) {
        return;
      }

      return {
        [`tolower(${property.name})`]: {
          ne: String(param).toLowerCase(),
        },
      };
    },
  },
  startsWith: {
    group: 'string functions',
    key: 'startsWith',
    label: 'starts with',
    getHints: () => addRequiredTagToHelperText(hintTexts.string),
    validate: ({ param }) => validate.string(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.string(param);

      if (param === null) {
        return;
      }

      return {
        [`startswith(${property.name}, '${param}')`]: { eq: true },
      };
    },
  },
  notStartsWith: {
    group: 'string functions',
    key: 'notStartsWith',
    label: 'does not start with',
    getHints: () => addRequiredTagToHelperText(hintTexts.string),
    validate: ({ param }) => validate.string(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.string(param);

      if (param === null) {
        return;
      }

      return `not startswith(${property.name}, '${param}')`;
    },
  },
  endsWith: {
    group: 'string functions',
    key: 'endsWith',
    label: 'ends with',
    getHints: () => addRequiredTagToHelperText(hintTexts.string),
    validate: ({ param }) => validate.string(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.string(param);

      if (param === null) {
        return;
      }

      return {
        [`endswith(${property.name}, '${param}')`]: { eq: true },
      };
    },
  },
  notEndsWith: {
    group: 'string functions',
    key: 'notEndsWith',
    label: 'does not end with',
    getHints: () => addRequiredTagToHelperText(hintTexts.string),
    validate: ({ param }) => validate.string(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.string(param);

      if (param === null) {
        return;
      }

      return `not endswith(${property.name}, '${param}')`;
    },
  },
  contains: {
    group: 'string functions',
    key: 'contains',
    label: 'contains',
    getHints: () => addRequiredTagToHelperText(hintTexts.string),
    validate: ({ param }) => validate.string(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.string(param);

      if (param === null) {
        return;
      }

      return {
        [`substringof('${param}', ${property.name})`]: {
          eq: true,
        },
      };
    },
  },
  notContains: {
    group: 'string functions',
    key: 'notContains',
    label: 'does not contain',
    getHints: () => addRequiredTagToHelperText(hintTexts.string),
    validate: ({ param }) => validate.string(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.string(param);

      if (param === null) {
        return;
      }

      return `not substringof('${param}', ${property.name})`;
    },
  },
  lengthEq: {
    key: 'lengthEq',
    group: 'length checks',
    label: 'length equals',
    getHints: () => addRequiredTagToHelperText(hintTexts.int),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.int(param);

      if (param === null) {
        return;
      }

      return {
        [`length(${property.name})`]: {
          eq: param,
        },
      };
    },
  },
  lengthNe: {
    key: 'lengthNe',
    group: 'length checks',
    label: 'length not equals',
    getHints: () => addRequiredTagToHelperText(hintTexts.int),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.int(param);

      if (param === null) {
        return;
      }

      return {
        [`length(${property.name})`]: {
          ne: param,
        },
      };
    },
  },
  lengthLt: {
    key: 'lengthLt',
    group: 'length checks',
    label: 'length less than',
    getHints: () => addRequiredTagToHelperText(hintTexts.int),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.int(param);

      if (param === null) {
        return;
      }

      return {
        [`length(${property.name})`]: {
          lt: param,
        },
      };
    },
  },
  lengthLe: {
    key: 'lengthLe',
    group: 'length checks',
    label: 'length less than or equal to',
    getHints: () => addRequiredTagToHelperText(hintTexts.int),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.int(param);

      if (param === null) {
        return;
      }

      return {
        [`length(${property.name})`]: {
          le: param,
        },
      };
    },
  },
  lengthGt: {
    key: 'lengthGt',
    group: 'length checks',
    label: 'length greater than',
    getHints: () => addRequiredTagToHelperText(hintTexts.int),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.int(param);

      if (param === null) {
        return;
      }

      return {
        [`length(${property.name})`]: {
          gt: param,
        },
      };
    },
  },
  lengthGe: {
    key: 'lengthGe',
    group: 'length checks',
    label: 'length greater than or equal to',
    getHints: () => addRequiredTagToHelperText(hintTexts.int),
    validate: ({ param }) => validate.int(param, { required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.int(param);

      if (param === null) {
        return;
      }

      return {
        [`length(${property.name})`]: {
          ge: param,
        },
      };
    },
  },
  guidEq: {
    key: 'guidEq',
    label: 'guid equals',
    getHints: ({ property: { nullable } }) =>
      nullable === 'false' ? addRequiredTagToHelperText(hintTexts.uuid) : hintTexts.uuid,
    validate: ({ property: { nullable }, param }) =>
      validate.uuid(param, { required: nullable === 'false' }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.uuid(param);

      if (!param && property.nullable === 'false') {
        return;
      }

      return `${property.name} eq ${param ? `guid'${param}'` : 'null'}`;
    },
  },
  guidNe: {
    key: 'guidNe',
    label: 'guid equals',
    getHints: ({ property: { nullable } }) =>
      nullable === 'false' ? addRequiredTagToHelperText(hintTexts.uuid) : hintTexts.uuid,
    validate: ({ property: { nullable }, param }) =>
      validate.uuid(param, { required: nullable === 'false' }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.uuid(param);

      if (!param && property.nullable === 'false') {
        return;
      }

      return `${property.name} ne ${param ? `guid'${param}'` : 'null'}`;
    },
  },

  datetimeEq: {
    key: 'datetimeEq',
    label: 'date equals',
    getQueryFilter(condition) {
      return getDateTimeFilterQuery(this.key, condition, true);
    },
  },
  datetimeNe: {
    key: 'datetimeNe',
    label: 'date not equals',
    getQueryFilter(condition) {
      return getDateTimeFilterQuery(this.key, condition, true);
    },
  },
  datetimeEqOrBefore: {
    key: 'datetimeEqOrBefore',
    label: 'date equals or before',
    getQueryFilter(condition) {
      return getDateTimeFilterQuery(this.key, condition);
    },
  },
  datetimeBefore: {
    key: 'datetimeBefore',
    label: 'date before',
    getQueryFilter(condition) {
      return getDateTimeFilterQuery(this.key, condition);
    },
  },
  datetimeAfter: {
    key: 'datetimeAfter',
    label: 'date after',
    getQueryFilter(condition) {
      return getDateTimeFilterQuery(this.key, condition);
    },
  },
  datetimeEqOrAfter: {
    key: 'datetimeEqOrAfter',
    label: 'date equals or after',
    getQueryFilter(condition) {
      return getDateTimeFilterQuery(this.key, condition);
    },
  },

  timeEq: {
    key: 'timeEq',
    label: 'time equals',
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.date(param);

      if (!param && property.nullable === 'false') {
        return;
      }

      return param
        ? `${property.name} eq time'${param.format('PTHH[H]mm[M]ss[S]')}'`
        : `${property.name} eq null`;
    },
  },
  timeNe: {
    key: 'timeNe',
    label: 'time not equals',
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.date(param);

      if (!param && property.nullable === 'false') {
        return;
      }

      return param
        ? `${property.name} ne time'${param.format('PTHH[H]mm[M]ss[S]')}'`
        : `${property.name} ne null`;
    },
  },
  timeEqOrBefore: {
    key: 'timeEqOrBefore',
    label: 'time equals or before',
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.date(param);

      if (!param) {
        return;
      }

      return `${property.name} le time'${param.format('PTHH[H]mm[M]ss[S]')}'`;
    },
  },
  timeBefore: {
    key: 'timeBefore',
    label: 'time before',
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.date(param);

      if (!param) {
        return;
      }

      return `${property.name} lt time'${param.format('PTHH[H]mm[M]ss[S]')}'`;
    },
  },
  timeAfter: {
    key: 'timeAfter',
    label: 'time after',
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.date(param);

      if (!param) {
        return;
      }

      return `${property.name} gt time'${param.format('PTHH[H]mm[M]ss[S]')}'`;
    },
  },
  timeEqOrAfter: {
    key: 'timeEqOrAfter',
    label: 'time equals or after',
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      param = cast.date(param);

      if (!param) {
        return;
      }

      return `${property.name} ge time'${param.format('PTHH[H]mm[M]ss[S]')}'`;
    },
  },

  yearEq: {
    key: 'yearEq',
    group: 'year',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'year equals',
    getHints: () => ({
      placeholder: dateParts.year.placeholder,
      helperText: `Must be between ${dateParts.year.min} and ${dateParts.year.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.year, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `year(${property.name}) eq ${param}`;
    },
  },
  yearNe: {
    key: 'yearNe',
    group: 'year',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'year not equals',
    getHints: () => ({
      placeholder: dateParts.year.placeholder,
      helperText: `Must be between ${dateParts.year.min} and ${dateParts.year.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.year, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `year(${property.name}) ne ${param}`;
    },
  },
  yearLe: {
    key: 'yearLe',
    group: 'year',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'year before or equals',
    getHints: () => ({
      placeholder: dateParts.year.placeholder,
      helperText: `Must be between ${dateParts.year.min} and ${dateParts.year.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.year, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `year(${property.name}) le ${param}`;
    },
  },
  yearLt: {
    key: 'yearLt',
    group: 'year',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'year before',
    getHints: () => ({
      placeholder: dateParts.year.placeholder,
      helperText: `Must be between ${dateParts.year.min + 1} and ${dateParts.year.max}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.year, min: dateParts.year.min + 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `year(${property.name}) lt ${param}`;
    },
  },
  yearGt: {
    key: 'yearGt',
    group: 'year',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'year after',
    getHints: () => ({
      placeholder: dateParts.year.placeholder,
      helperText: `Must be between ${dateParts.year.min} and ${dateParts.year.max - 1}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.year, max: dateParts.year.max - 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `year(${property.name}) gt ${param}`;
    },
  },
  yearGe: {
    key: 'yearGe',
    group: 'year',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'year equals or after',
    getHints: () => ({
      placeholder: dateParts.year.placeholder,
      helperText: `Must be between ${dateParts.year.min} and ${dateParts.year.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.year, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `year(${property.name}) ge ${param}`;
    },
  },

  monthEq: {
    key: 'monthEq',
    group: 'month',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'month equals',
    getHints: () => ({
      placeholder: dateParts.month.placeholder,
      helperText: `Must be between ${dateParts.month.min} and ${dateParts.month.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.month, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `month(${property.name}) eq ${param}`;
    },
  },
  monthNe: {
    key: 'monthNe',
    group: 'month',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'month not equals',
    getHints: () => ({
      placeholder: dateParts.month.placeholder,
      helperText: `Must be between ${dateParts.month.min} and ${dateParts.month.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.month, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `month(${property.name}) ne ${param}`;
    },
  },
  monthLe: {
    key: 'monthLe',
    group: 'month',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'month before or equals',
    getHints: () => ({
      placeholder: dateParts.month.placeholder,
      helperText: `Must be between ${dateParts.month.min} and ${dateParts.month.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.month, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `month(${property.name}) le ${param}`;
    },
  },
  monthLt: {
    key: 'monthLt',
    group: 'month',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'month before',
    getHints: () => ({
      placeholder: dateParts.month.placeholder,
      helperText: `Must be between ${dateParts.month.min} and ${dateParts.month.max}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.month, min: dateParts.month.min + 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `month(${property.name}) lt ${param}`;
    },
  },
  monthGt: {
    key: 'monthGt',
    group: 'month',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'month after',
    getHints: () => ({
      placeholder: dateParts.month.placeholder,
      helperText: `Must be between ${dateParts.month.min} and ${dateParts.month.max - 1}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.month, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `month(${property.name}) gt ${param}`;
    },
  },
  monthGe: {
    key: 'monthGe',
    group: 'month',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'month equals or after',
    getHints: () => ({
      placeholder: dateParts.month.placeholder,
      helperText: `Must be between ${dateParts.month.min} and ${dateParts.month.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.month, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `month(${property.name}) ge ${param}`;
    },
  },

  dayEq: {
    key: 'dayEq',
    group: 'day',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'day equals',
    getHints: () => ({
      placeholder: dateParts.day.placeholder,
      helperText: `Must be between ${dateParts.day.min} and ${dateParts.day.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.day, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `day(${property.name}) eq ${param}`;
    },
  },
  dayNe: {
    key: 'dayNe',
    group: 'day',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'day not equals',
    getHints: () => ({
      placeholder: dateParts.day.placeholder,
      helperText: `Must be between ${dateParts.day.min} and ${dateParts.day.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.day, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `day(${property.name}) ne ${param}`;
    },
  },
  dayLe: {
    key: 'dayLe',
    group: 'day',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'day before or equals',
    getHints: () => ({
      placeholder: dateParts.day.placeholder,
      helperText: `Must be between ${dateParts.day.min} and ${dateParts.day.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.day, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `day(${property.name}) le ${param}`;
    },
  },
  dayLt: {
    key: 'dayLt',
    group: 'day',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'day before',
    getHints: () => ({
      placeholder: dateParts.day.placeholder,
      helperText: `Must be between ${dateParts.day.min + 1} and ${dateParts.day.max}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.day, min: dateParts.day.min + 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `day(${property.name}) lt ${param}`;
    },
  },
  dayGt: {
    key: 'dayGt',
    group: 'day',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'day after',
    getHints: () => ({
      placeholder: dateParts.day.placeholder,
      helperText: `Must be between ${dateParts.day.min} and ${dateParts.day.max - 1}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.day, max: dateParts.day.max - 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `day(${property.name}) gt ${param}`;
    },
  },
  dayGe: {
    key: 'dayGe',
    group: 'day',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'day equals or after',
    getHints: () => ({
      placeholder: dateParts.day.placeholder,
      helperText: `Must be between ${dateParts.day.min} and ${dateParts.day.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.day, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `day(${property.name}) ge ${param}`;
    },
  },

  hourEq: {
    key: 'hourEq',
    group: 'hour',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'hour equals',
    getHints: () => ({
      placeholder: dateParts.hour.placeholder,
      helperText: `Must be between ${dateParts.hour.min} and ${dateParts.hour.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.hour, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `hour(${property.name}) eq ${param}`;
    },
  },
  hourNe: {
    key: 'hourNe',
    group: 'hour',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'hour not equals',
    getHints: () => ({
      placeholder: dateParts.hour.placeholder,
      helperText: `Must be between ${dateParts.hour.min} and ${dateParts.hour.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.hour, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `hour(${property.name}) eq ${param}`;
    },
  },
  hourLe: {
    key: 'hourLe',
    group: 'hour',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'hour before or equals',
    getHints: () => ({
      placeholder: dateParts.hour.placeholder,
      helperText: `Must be between ${dateParts.hour.min} and ${dateParts.hour.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.hour, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `hour(${property.name}) le ${param}`;
    },
  },
  hourLt: {
    key: 'hourLt',
    group: 'hour',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'hour before',
    getHints: () => ({
      placeholder: dateParts.hour.placeholder,
      helperText: `Must be between ${dateParts.hour.min + 1} and ${dateParts.hour.max}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.hour, min: dateParts.hour.min + 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `hour(${property.name}) lt ${param}`;
    },
  },
  hourGt: {
    key: 'hourGt',
    group: 'hour',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'hour after',
    getHints: () => ({
      placeholder: dateParts.hour.placeholder,
      helperText: `Must be between ${dateParts.hour.min} and ${dateParts.hour.max - 1}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.hour, max: dateParts.hour.max - 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `hour(${property.name}) gt ${param}`;
    },
  },
  hourGe: {
    key: 'hourGe',
    group: 'hour',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'hour equals or after',
    getHints: () => ({
      placeholder: dateParts.hour.placeholder,
      helperText: `Must be between ${dateParts.hour.min} and ${dateParts.hour.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.hour, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `hour(${property.name}) ge ${param}`;
    },
  },

  minuteEq: {
    key: 'minuteEq',
    group: 'minute',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'minute equals',
    getHints: () => ({
      placeholder: dateParts.minute.placeholder,
      helperText: `Must be between ${dateParts.minute.min} and ${dateParts.minute.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.minute, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `minute(${property.name}) eq ${param}`;
    },
  },
  minuteNe: {
    key: 'minuteNe',
    group: 'minute',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'minute not equals',
    getHints: () => ({
      placeholder: dateParts.minute.placeholder,
      helperText: `Must be between ${dateParts.minute.min} and ${dateParts.minute.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.minute, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `minute(${property.name}) ne ${param}`;
    },
  },
  minuteLe: {
    key: 'minuteLe',
    group: 'minute',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'minute before or equals',
    getHints: () => ({
      placeholder: dateParts.minute.placeholder,
      helperText: `Must be between ${dateParts.minute.min} and ${dateParts.minute.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.minute, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `minute(${property.name}) le ${param}`;
    },
  },
  minuteLt: {
    key: 'minuteLt',
    group: 'minute',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'minute before',
    getHints: () => ({
      placeholder: dateParts.minute.placeholder,
      helperText: `Must be between ${dateParts.minute.min + 1} and ${dateParts.minute.max}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.minute, min: dateParts.minute.min + 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `minute(${property.name}) lt ${param}`;
    },
  },
  minuteGt: {
    key: 'minuteGt',
    group: 'minute',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'minute after',
    getHints: () => ({
      placeholder: dateParts.minute.placeholder,
      helperText: `Must be between ${dateParts.minute.min} and ${dateParts.minute.max - 1}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.minute, max: dateParts.minute.max - 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `minute(${property.name}) gt ${param}`;
    },
  },
  minuteGe: {
    key: 'minuteGe',
    group: 'minute',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'minute equals or after',
    getHints: () => ({
      placeholder: dateParts.minute.placeholder,
      helperText: `Must be between ${dateParts.minute.min} and ${dateParts.minute.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.minute, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `minute(${property.name}) ge ${param}`;
    },
  },

  secondEq: {
    key: 'secondEq',
    group: 'second',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'second equals',
    getHints: () => ({
      placeholder: dateParts.second.placeholder,
      helperText: `Must be between ${dateParts.second.min} and ${dateParts.second.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.second, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `second(${property.name}) eq ${param}`;
    },
  },
  secondNe: {
    key: 'secondNe',
    group: 'second',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'second not equals',
    getHints: () => ({
      placeholder: dateParts.second.placeholder,
      helperText: `Must be between ${dateParts.second.min} and ${dateParts.second.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.second, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `second(${property.name}) ne ${param}`;
    },
  },
  secondLe: {
    key: 'secondLe',
    group: 'second',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'second before or equals',
    getHints: () => ({
      placeholder: dateParts.second.placeholder,
      helperText: `Must be between ${dateParts.second.min} and ${dateParts.second.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.second, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `second(${property.name}) le ${param}`;
    },
  },
  secondLt: {
    key: 'secondLt',
    group: 'second',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'second before',
    getHints: () => ({
      placeholder: dateParts.second.placeholder,
      helperText: `Must be between ${dateParts.second.min + 1} and ${dateParts.second.max}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.second, min: dateParts.second.min - 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `second(${property.name}) lt ${param}`;
    },
  },
  secondGt: {
    key: 'secondGt',
    group: 'second',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'second after',
    getHints: () => ({
      placeholder: dateParts.second.placeholder,
      helperText: `Must be between ${dateParts.second.min} and ${dateParts.second.max - 1}`,
    }),
    validate: ({ param }) =>
      validate.int(param, { ...dateParts.second, max: dateParts.second.max - 1, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `second(${property.name}) gt ${param}`;
    },
  },
  secondGe: {
    key: 'secondGe',
    group: 'second',
    disabled: ({ isSfSystem }) => isSfSystem,
    label: 'second equals or after',
    getHints: () => ({
      placeholder: dateParts.second.placeholder,
      helperText: `Must be between ${dateParts.second.min} and ${dateParts.second.max}`,
    }),
    validate: ({ param }) => validate.int(param, { ...dateParts.second, required: true }),
    getQueryFilter(condition) {
      const { property } = condition;
      let { param } = condition;

      if (!this.validate({ param })) {
        return;
      }

      param = cast.int(param);

      return `second(${property.name}) ge ${param}`;
    },
  },
};

/**
 * an object map that contains the list of filter options supported for a data type
 */
export const typeBasedFilterOptions = {
  [ODATA_DATA_TYPES['Edm.Boolean']]: [filterOptions.isTrue, filterOptions.isFalse],
  [ODATA_DATA_TYPES['Edm.Int16']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
  ],
  [ODATA_DATA_TYPES['Edm.Int32']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
  ],
  [ODATA_DATA_TYPES['Edm.Int64']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
  ],
  [ODATA_DATA_TYPES['Edm.Single']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
    filterOptions.roundEq,
    filterOptions.floorEq,
    filterOptions.ceilingEq,
  ],
  [ODATA_DATA_TYPES['Edm.Double']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
    filterOptions.roundEq,
    filterOptions.floorEq,
    filterOptions.ceilingEq,
  ],
  [ODATA_DATA_TYPES['Edm.Decimal']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.gt,
    filterOptions.ge,
    filterOptions.lt,
    filterOptions.le,
    filterOptions.in,
    filterOptions.roundEq,
    filterOptions.floorEq,
    filterOptions.ceilingEq,
  ],
  [ODATA_DATA_TYPES['Edm.String']]: [
    filterOptions.eq,
    filterOptions.ne,
    filterOptions.in,
    filterOptions.caseInsensitiveEq,
    filterOptions.caseInsensitiveNe,
    filterOptions.startsWith,
    filterOptions.notStartsWith,
    filterOptions.endsWith,
    filterOptions.notEndsWith,
    filterOptions.contains,
    filterOptions.notContains,
    filterOptions.lengthEq,
    filterOptions.lengthNe,
    filterOptions.lengthLt,
    filterOptions.lengthLe,
    filterOptions.lengthGt,
    filterOptions.lengthGe,
  ],
  [ODATA_DATA_TYPES['Edm.Guid']]: [filterOptions.guidEq, filterOptions.guidNe],
  [ODATA_DATA_TYPES['Edm.DateTime']]: [
    filterOptions.datetimeEq,
    filterOptions.datetimeNe,
    filterOptions.datetimeEqOrBefore,
    filterOptions.datetimeBefore,
    filterOptions.datetimeAfter,
    filterOptions.datetimeEqOrAfter,
  ],
  [ODATA_DATA_TYPES['Edm.DateTimeOffset']]: [
    filterOptions.datetimeEq,
    filterOptions.datetimeNe,
    filterOptions.datetimeEqOrBefore,
    filterOptions.datetimeBefore,
    filterOptions.datetimeAfter,
    filterOptions.datetimeEqOrAfter,
  ],
  [ODATA_DATA_TYPES['Edm.Time']]: [
    filterOptions.timeEq,
    filterOptions.timeNe,
    filterOptions.timeEqOrBefore,
    filterOptions.timeBefore,
    filterOptions.timeAfter,
    filterOptions.timeEqOrAfter,
  ],
};

/**
 * all the data type for which filter operators are available and hence can be used to query
 */
export const queryableDataTypes = keys(typeBasedFilterOptions);

/**
 * @function
 *
 * utility function to check if a data type can be queried on
 *
 * @param {string} dataType       name of the datatype
 *
 * @returns {boolean}
 */
export const canQueryDataType = (dataType) => queryableDataTypes.includes(dataType);

/**
 * @function
 *
 * mapper function for getting the expand column names
 * @param {object} column         selected expand column
 *
 * @returns {string[]}            column name prefixed with the parent
 */
const expandedColumnNameMapper = (column) => column.name;

/**
 * @function
 *
 * function to create the filter opeators object for building query
 *
 * @param {object} where                  selected filter operators with param & config values
 *
 * @returns {object}           filters to use in the query
 */
const createFilterObject = (where) => {
  const filters = {};

  if (where.rules.length) {
    filters[where.operator] = [];

    const traverseRules = (stateSlice, filterSlice) => {
      stateSlice.rules.forEach((rule, ruleIndex) => {
        if (rule.operator && rule.rules.length) {
          const sliceLength = filterSlice.push({ [rule.operator]: [] });

          traverseRules(stateSlice.rules[ruleIndex], filterSlice[sliceLength - 1][rule.operator]);
        } else {
          const filterString = filterOptions[rule.filter?.key]?.getQueryFilter?.(rule);
          if (filterString) {
            filterSlice.push(filterString);
          }
        }
      });
    };

    traverseRules(where, filters[where.operator]);
  }

  return filters;
};
export const memoizedCreateFilterObject = memoizeOne(createFilterObject);

/**
 * @function
 *
 * function to create the select object for building query
 *
 * Note: using position parameters instead of pojo as a single parametert to ease memoization process
 *
 * @param {object[]} columnSelectOptions                options for column select
 * @param {object[]} columnSelect                     selected columns
 * @param {object[]} expand                           selected expand columns
 * @param {'include' | 'exclude'} columnSelectFlag    flag containing enum
 *
 * @returns {object[]}                                array of select parameters
 */
const createSelectObject = (columnSelectOptions, columnSelect, expand, columnSelectFlag) => {
  if (columnSelect.length === 0) {
    return;
  }

  const selectedColumnNames = columnSelect.map(({ name }) => name);

  let select = columnSelectOptions.reduce((selected, property) => {
    const isSelected = selectedColumnNames.includes(property.name);

    if (columnSelectFlag === ENUMS.COLUMN_SELECT_FLAG.INCLUDE && isSelected) {
      selected.push(property.name);
    } else if (columnSelectFlag === ENUMS.COLUMN_SELECT_FLAG.EXCLUDE && !isSelected) {
      selected.push(property.name);
    }

    return selected;
  }, []);
  select = select.concat(
    expand
      .map((expandedValue) => {
        const hasProjectedColumn = select.some((selected) => {
          if (selected.startsWith(`${expandedValue.name}/`)) {
            const sliced = selected.slice(expandedValue.name.length + 1);

            const level = sliced.split('/').length;

            if (level === 1) {
              return true;
            }
          }

          return false;
        });

        if (hasProjectedColumn) {
          return null;
        }

        return expandedValue.name;
      })
      .filter(Boolean)
  );

  return select.length ? select : null;
};
const memoizedCreateSelectObject = memoizeOne(createSelectObject);

/**
 * @function
 *
 * function to create order by object for building query
 *
 * @param {object[]} selectedOrderByColumns                 columns selected for order by setup
 * @param {Array<'asc' | 'desc'>} columnOrderByFlagState    order flag state for each column
 *
 * @returns {string[]}                                      column names with order state
 */
const createOrderByObject = (selectedOrderByColumns, columnOrderByFlagState) => {
  return selectedOrderByColumns.map((column) =>
    columnOrderByFlagState[column.name] === ENUMS.ORDER_BY_FLAG.DESC
      ? `${column.name} desc`
      : column.name
  );
};
export const memoizedCreateOrderByObject = memoizeOne(createOrderByObject);

/**
 * @function
 *
 * function to create expand query string for building query
 *
 * @param {object[]} selectedExpandColumns                columns selected for expand
 *
 * @returns {string}                                      querty string string
 */
const createExpandQueryString = (selectedExpandColumns) => {
  if (selectedExpandColumns.length === 0) {
    return '';
  }

  return `&$expand=${selectedExpandColumns.map(expandedColumnNameMapper).join(',')}`;
};
const memoizedCreateExpandQueryString = memoizeOne(createExpandQueryString);

/**
 * @function
 *
 * function to create the query string
 *
 * @param {object} params
 * @param {string} params.systemType                              type of the OData system
 * @param {object} params.schema                                  schema object from the parsed metadata
 * @param {object} params.entity                                  selected entity object
 * @param {number} params.top                                     no. of records to fetch
 * @param {number} params.skip                                    no. of records to skip
 * @param {Date | null} params.asOfDate                           as of date parameter for effective dates range queries
 * @param {Date | null} params.fromDate                           from date parameter for effective dates range queries
 * @param {Date | null} params.toDate                             to date parameter for effective dates range queries
 * @param {object} params.where                                   object containing the where condition structure
 * @param {object[]} params.expand                                columns to include in expand parameter
 * @param {object[]} params.columnSelectOptions                   options for column select
 * @param {object[]} params.columnSelect                          columns to include in select parameter
 * @param {'include' | 'exclude'} params.columnSelectFlag         flag to include or exclude the selected columns
 * @param {object[]} params.orderBy                               columns to include in orderBy parameter
 * @param {{ string: 'asc' | 'desc' }} params.orderByFlags       order by flag for each column
 *
 * @return {string} the query string
 */
export const createQuery = ({
  entity,
  schema,
  top,
  skip,
  asOfDate,
  fromDate,
  toDate,
  where,
  expand,
  columnSelectOptions,
  columnSelect,
  columnSelectFlag,
  orderBy: _orderBy,
  orderByFlags,
  systemType,
}) => {
  const filter = memoizedCreateFilterObject(where);

  const select = memoizedCreateSelectObject(
    columnSelectOptions,
    columnSelect,
    expand,
    columnSelectFlag
  );
  const orderBy = memoizedCreateOrderByObject(_orderBy, orderByFlags);
  const expandQueryStringSlice = memoizedCreateExpandQueryString(expand);

  let queryString = buildQuery({
    top: clamp(top ?? PAGINATION.TOP.DEFAULT, PAGINATION.TOP.MIN, PAGINATION.TOP.MAX),
    skip: clamp(skip ?? PAGINATION.SKIP.DEFAULT, PAGINATION.SKIP.MIN, PAGINATION.SKIP.MAX),
    filter,
    select,
    orderBy,
  });

  if (systemType === SYSTEMS.SF_EC.KEY) {
    if (asOfDate) {
      if (checkIsVariableValid(asOfDate.key)) {
        queryString += `&asOfDate=${asOfDate.key}`;
      } else {
        queryString += `&asOfDate=${asOfDate.format('YYYY-MM-DD')}`;
      }
    } else {
      if (fromDate) {
        if (checkIsVariableValid(fromDate.key)) {
          queryString += `&fromDate=${fromDate.key}`;
        } else {
          queryString += `&fromDate=${fromDate.format('YYYY-MM-DD')}`;
        }
      }

      if (toDate) {
        if (checkIsVariableValid(toDate.key)) {
          queryString += `&toDate=${toDate.key}`;
        } else {
          queryString += `&toDate=${toDate.format('YYYY-MM-DD')}`;
        }
      }
    }
  }

  return `${getEntitySetName({
    schema,
    entity,
  })}${queryString}${expandQueryStringSlice}`;
};

const translateEffectiveRangeDates = (queryString) => {
  // The effective dates in a queryString show up as asOfDate=$TODAY$ / fromDate=$TODAY$ / toDate=$TDOAY$,
  // we are using the below regular exp to check if the query string contains any effective dates.
  const effectiveDates = queryString.match(
    /asOfDate=[$][A-Z]+[$]|toDate=[$][A-Z]+[$]|fromDate=[$][A-Z]+[$]/g
  );

  // If there are effective dates present in the queryString, we continue with translating them
  if (effectiveDates && effectiveDates.length > 0) {
    effectiveDates.forEach((effectiveDate) => {
      // We split the effectiveDate into two parts from '='. So, asOfDate=$TODAY$ becomes
      // [range, variable] : ['asOfDate','$TODAY$']
      const [range, variable] = effectiveDate.split('=');
      // We replace the effectiveDate string in the queryString with the translated version of the variable
      queryString = queryString.replace(
        effectiveDate,
        `${range}=${relativeDateTimeOptions[variable].translate(customFormats.date)}`
      );
    });
  }

  return queryString;
};

const translateOtherDates = (queryString) => {
  try {
    // The below regex, gets us all the variables present in the queryString
    const variablesInQueryString = [];
    const regex = /(datetimeoffset|datetime)'([^']+)'/g;

    // Loop through the matches and populate the result array
    let match;
    while ((match = regex.exec(queryString)) !== null) {
      // match[1] will contain the type of the property (like 'datetimeoffset', 'datetime', etc.)
      // match[2] will contain the value (like '$TODAY$')
      variablesInQueryString.push({
        property: match[1],
        value: match[2],
      });
    }

    // If there are variables present in the queryString, we continue with translating them
    if (variablesInQueryString && variablesInQueryString.length > 0) {
      variablesInQueryString.forEach(({ property, value }) => {
        const isDateTimeOffset = property === 'datetimeoffset';
        const tz = isDateTimeOffset ? getUTCOffsetInODataFormat() : '';

        // If the variable is valid, we replace it with the translated datetime value
        if (checkIsVariableValid(value)) {
          queryString = queryString.replace(
            value,
            relativeDateTimeOptions[value].translate(`YYYY-MM-DDTHH:mm:ss${tz}`)
          );
        }
      });
    }
  } catch {
    // Fallback logic for if the string parsing and property-name extraction fails

    // The below regex, gets us all the variables present in the queryString
    const variablesInQueryString = queryString.match(/[$][A-Z]+[$]/g);

    // If there are variables present in the queryString, we continue with translating them
    if (variablesInQueryString && variablesInQueryString.length > 0) {
      variablesInQueryString.forEach((variable) => {
        // If the variable is valid, we replace it with the translated datetime value
        if (checkIsVariableValid(variable)) {
          queryString = queryString.replace(
            variable,
            relativeDateTimeOptions[variable].translate('YYYY-MM-DDTHH:mm:ss')
          );
        }
      });
    }
  } finally {
    return queryString;
  }
};

/**
 *  This function accepts a queryString and translates the variables in the query string to the respective date string
 *
 * @param {string} queryString                 the query string you want to translate
 * @returns
 */
export const translateVariablesToDateTimeString = (queryString) => {
  // We translate effective ranges seperately because the data type of effective range dates is date
  queryString = translateEffectiveRangeDates(queryString);
  // All other dates are of data type datetime, and they are translated in the below function
  queryString = translateOtherDates(queryString);

  return queryString;
};

/**
 * Generates a datetime filter query string based on various conditions, including
 * whether the datetime is relative, the time zone, and special conditions for
 * 'lastModifiedDateTime'.
 *
 * @param {Object} property - The property object with the name of the field to filter.
 * @param {Object} param - The date parameter. Can be relative or an object with a 'format' method.
 * @param {string} operator - The comparison operator (e.g., 'ge', 'le').
 * @param {string} offset - The datetime offset (e.g., '+01:00' for UTC+1).
 * @param {boolean} isRelative - Flag indicating if the datetime is relative.
 * @param {string} tz - The time zone (e.g., 'Z', '+01:00').
 *
 * @returns {string} The generated datetime filter query string.
 *
 * @see {@link https://help.sap.com/docs/SAP_SUCCESSFACTORS_PLATFORM/d599f15995d348a1b45ba5603e2aba9b/73cfd57b6db440adb7b8a6f5d5ac018a.html}
 */
function getDateTimeFilterQuery(key, condition, checkNullable = false) {
  const { property } = condition;
  let { param } = condition;

  const isRelative = param?.type === 'relative' ? true : false;

  const isDateTimeOffset = property.type === ODATA_DATA_TYPES['Edm.DateTimeOffset'];
  const offset = isDateTimeOffset ? 'offset' : '';
  const tz = isDateTimeOffset ? getUTCOffsetInODataFormat() : '';

  if (param && isRelative) {
    param = param.key;
  } else {
    param = cast.date(param);
  }

  const isNotNullable = checkNullable ? property.nullable === 'false' : true;

  if (!param && isNotNullable) {
    return;
  }

  // If it is a lastModifiedDateTime condition, we need to flip the query
  const flip = property.name === 'lastModifiedDateTime' || property.name === 'lastModifiedOn';
  const operator = getOperatorForKey(key, flip);
  const paramValue = isRelative ? param : `${param?.format(`YYYY-MM-DDTHH:mm:ss`)}${tz}`;

  // If it is a lastModifiedDateTime condition we need to flip the query to avoid including records
  // from the audit log. https://help.sap.com/docs/SAP_SUCCESSFACTORS_PLATFORM/d599f15995d348a1b45ba5603e2aba9b/73cfd57b6db440adb7b8a6f5d5ac018a.html
  if (flip) {
    return param
      ? `datetime${offset}'${paramValue}' ${operator} ${property.name}`
      : `null ${operator} ${property.name}`;
  }

  // Why are we checking param and not paramValue?
  // Because if param is null paramValue will look like "undefined+1000" which is a truthy value. This is why
  // we are checking if param is null to render out the relevant query string
  return param
    ? `${property.name} ${operator} datetime${offset}'${paramValue}'`
    : `${property.name} ${operator} null`;
}

/**
 * Get the appropriate OData operator for a given filter option key.
 *
 * @param {string} operator - The filter option key for which to get the OData operator.
 * @param {boolean} shouldFlip - Determines whether the operator should be flipped. For example, 'ge' becomes 'le'.
 * @returns {string|null} Returns the OData operator corresponding to the given filter option key. Returns null if the key doesn't match any predefined keys.
 *
 * @example
 * // returns 'eq'
 * getOperatorForKey('datetimeEqKey', false);
 *
 * // returns 'ne'
 * getOperatorForKey('datetimeNeKey', false);
 *
 * // returns 'le'
 * getOperatorForKey('datetimeEqOrBeforeKey', false);
 *
 * // returns 'ge'
 * getOperatorForKey('datetimeEqOrBeforeKey', true);
 */
function getOperatorForKey(operator, shouldflip) {
  switch (operator) {
    case filterOptions.datetimeEq['key']:
      return 'eq';
    case filterOptions.datetimeNe['key']:
      return 'ne';
    case filterOptions.datetimeEqOrBefore['key']:
      return shouldflip ? 'ge' : 'le';
    case filterOptions.datetimeBefore['key']:
      return shouldflip ? 'gt' : 'lt';
    case filterOptions.datetimeAfter['key']:
      return shouldflip ? 'lt' : 'gt';
    case filterOptions.datetimeEqOrAfter['key']:
      return shouldflip ? 'le' : 'ge';
    default:
      return null;
  }
}
